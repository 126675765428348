import * as THREE from "three"
import { sceneManager } from "../index"
import { camera, scene } from "../SceneManager"

class SelectedManager {
	constructor() {
		this.subject = null
		this.prop = null
		this.character = null
		this.propToConnect = null
	}

	tapHandler(event, secondary = false) {
		const { clientX, clientY, which } = event

		if (sceneManager.activeGizmo == "hidden") return
		if (sceneManager.gizmo.dragging) return
		
		// Deselect everything
		if (this.subject) {
			this.subject.deselect()
		}

		sceneManager.directionalLight.deselect()
		if (this.prop) {
			this.prop.deselect()
		}

		// If right click, everything remains deselected
		if (which == 3) {
			this.subject.deselect()
			return
		} 

		const closest = this.findClosest(clientX, clientY)
		
		if (closest) {
			if (!secondary) {
				closest.object.onTap(closest.itemClicked)
			} else {
				closest.object.onSecondaryTap(closest.itemClicked)
			}
		} else {
			this.propToConnect = null
		}
	}

	findClosest(clientX, clientY) {
		const mouse = new THREE.Vector2()
		const raycaster = new THREE.Raycaster()

		mouse.x =
			((clientX - sceneManager.canvas.offsetLeft) /
				sceneManager.canvas.clientWidth) *
				2 -
			1
		mouse.y =
			-(
				(clientY - sceneManager.canvas.offsetTop) /
				sceneManager.canvas.clientHeight
			) *
				2 +
			1

		raycaster.setFromCamera(mouse, camera)

		let intersects = []

		sceneManager.sceneCharacters.forEach((sceneCharacter) => {
			if (!sceneCharacter.locked && sceneCharacter.object && sceneCharacter.object.visible) {
				intersects.push(sceneCharacter.checkIntersects(raycaster))
			}
		})

		sceneManager.sceneProps.forEach((sceneProp) => {
			if (sceneProp.alive && !sceneProp.locked && sceneProp.object.visible) {
				intersects.push(sceneProp.checkIntersects(raycaster))
			}
		})

		sceneManager.lights.forEach((light) => {
			intersects.push(light.checkIntersects(raycaster))
		})

		const closest = intersects
			.sort((a, b) => a.distance - b.distance)
			.filter(
				(item, index, array) => item && item.distance === array[0].distance
			)[0]

		if (closest && closest.object) {
			return closest
		} else {
			return false
		}
	}

	select(subject) {
		document.querySelectorAll("#characters li").forEach((item) => {
			item.classList.remove("active")
		})
		this.subject = subject

		if (subject.subjectType == "Character") {
			this.character = subject
		} else if (subject.subjectType == "Prop") {
			this.prop = subject
		} else {
			console.warn(subject.subjectType, "Not recognized")
		}

		document.getElementById(subject.id).classList.add("active")
	}
}

export default SelectedManager
