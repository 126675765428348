import * as THREE from "three"
import { animateTransition } from "../helpers/Helpers"
import { sceneManager } from ".."

export default class Subject {
	constructor() {}

	addHelper() {
		const box = new THREE.Box3()
		box.setFromCenterAndSize(
			new THREE.Vector3(0, 0, 0),
			new THREE.Vector3(3, 3, 3)
		)

		const helper = new THREE.Box3Helper(box, 0xff0000)
		this.object.add(helper)
	}
    
	print() {
		console.log(this)
	}

	getSpawnPoint(subjectType) {
		let points = []
		for (let row = 0; row<10; row++) {
			const z = subjectType == "Character" ? row*-70 : (row*70)+70
			points.push(new THREE.Vector3(0, 70, z))
			points.push(new THREE.Vector3(70, 70, z))
			points.push(new THREE.Vector3(-70, 70, z))
			points.push(new THREE.Vector3(140, 70, z))
			points.push(new THREE.Vector3(-140, 70, z))
			points.push(new THREE.Vector3(210, 70, z))
			points.push(new THREE.Vector3(-210, 70, z))
		}
		const position = subjectType == "Character" ? sceneManager.sceneCharacters.length-1 : sceneManager.sceneProps.length
		
		return points[position]
	}

	getPosition() {
		if (this.base) {
			return new THREE.Vector3().copy(this.base.position)
		}
	}

	setPosition(position, animate=true) {
		if (!position) {
			if (this.subjectType == "Character") {
				if (!this.info.centeredOnly) {
					this.base.position.copy(this.getSpawnPoint(this.subjectType))
				}
				this.putOnGround(animate)
			} else {
				this.base.position.copy(this.getSpawnPoint(this.subjectType))
			}
		} else {
			if (animate) {
				animateTransition(this.base.position, position, tween => this.base.position.copy(tween))
			} else {
				this.base.position.copy(position)
			}
		}
	}

	getScale() {
		if (this.base) {
			return new THREE.Vector3().copy(this.base.scale)
		}
	}

	setScale(scale) {
		if (!scale) return

		animateTransition(this.base.scale, scale, tween => this.base.scale.copy(tween))
	}

	getRotation() {
        return new THREE.Vector3().copy(this.object.rotation)
	}

	setRotation(rotation) {
		if (!rotation) return
		animateTransition({
			x: this.base.rotation.x,
			y: this.base.rotation.y,
			z: this.base.rotation.z,

		},
		rotation,
		value => this.base.rotation.set(value.x, value.y, value.z))
	}

    getState() {
        const state = {
            world: {
                position: new THREE.Vector3(),
                quaternion: new THREE.Quaternion(),
                scale: new THREE.Vector3()
            },
            local: {
                position: new THREE.Vector3(),
                quaternion: new THREE.Quaternion(),
                scale: new THREE.Vector3()
            },
        }
        this.object.matrixWorld.decompose( state.world.position, state.world.quaternion, state.world.scale );
        this.base.matrixWorld.decompose( state.local.position, state.local.quaternion, state.local.scale );

        return state
    }

    setState(state) {
        if (!state) return

        this.object.position.copy(state.world.position)
        this.object.scale.copy(state.world.scale)
        this.object.applyQuaternion(state.world.quaternion)
    }
}
