import { sceneManager } from ".."

document.addEventListener("keydown", (event) => {
	let inputActive = false
	document.querySelectorAll(".text-input").forEach((input) => {
		if (input == document.activeElement) {
			inputActive = true
		}
	})
	if (!inputActive) {
		if (
			(event.metaKey || event.ctrlKey) &&
			event.shiftKey &&
			event.key === "z"
		) {
			sceneManager.stateManager.redo()
		} else if ((event.metaKey || event.ctrlKey) && event.key === "z") {
			sceneManager.stateManager.undo()
		}

		if (event.code == "Delete" || event.code == "Backspace") {
			sceneManager.selectedManager.subject.delete()
		}

		if (event.key == "q") {
			sceneManager.setGizmo("rotate")
		}
		if (event.key == "w") {
			sceneManager.setGizmo("translate")
		}
		if (event.key == "e") {
			sceneManager.setGizmo("scale")
		}
		if (event.key == "r") {
			sceneManager.setGizmo("hidden")
		}
		if (event.key == "l") {
			sceneManager.lockCamera(document.querySelector("#lock-camera"))
		}
		if (event.key == "j") {
			sceneManager.toggleMirrorPosingMode(document.querySelector("#mirror-posing"))
		}
		if (event.key == "Escape") {
			sceneManager.selectedManager.character.exitHandEditor()
		}
	}
})
