import * as THREE from "three";

import { scene, orbitControl } from '../SceneManager';
import { sceneManager } from '../index';

class Light {
	constructor(properties, position, canMove) {
		this.object = new THREE.Object3D()
		this.light = properties;
		this.canMove = canMove;

		if (canMove) {
			const geometry = new THREE.SphereBufferGeometry(3, 15, 15);
			const material = new THREE.MeshBasicMaterial({ color: 0xffff00 })
			this.object = new THREE.Mesh(geometry, material);

			this.light.castShadow = true;
			this.light.shadow.camera.top = 180;
			this.light.shadow.camera.bottom = - 100;
			this.light.shadow.camera.left = - 120;
			this.light.shadow.camera.right = 120;
			this.light.shadow.mapSize.width = 1024;
			this.light.shadow.mapSize.height = 1024;
		}

		this.light.add(this.object);
		scene.add(this.light);
		this.light.position.copy(position)
	}

	toggleShadows(castShadow) {
		this.light.castShadow = castShadow;
	}

	setVisibility(value) {
		this.object.visible = value;
	}

	setIntensity(intensity) {
		this.light.intensity = intensity;
	}

	checkIntersects(raycaster) {
		if (!this.canMove) return;
		const intersects = raycaster.intersectObject(this.object);
		if (intersects.length > 0) {
			const closest = intersects.sort((a, b) => a.distance - b.distance)
				.filter((item, index, array) => item.distance === array[0].distance)[0];
			return { object: this, distance: closest.distance, itemClicked: closest.object };
		}
	}

	onTap() {
		sceneManager.setGizmo('translate');
		sceneManager.gizmo.updateObject(this.light);
	}

	deselect() {
		if (orbitControl.enabled) {
			if (this.light == sceneManager.gizmo.object) {
				sceneManager.gizmo.detach();
			}
		}
	}

	hover() {
		this.object.material.opacity = 0.5;
	}

	unhover() {
		this.object.material.opacity = 1;
	}
}

export default Light;