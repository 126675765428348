import * as THREE from "three";
import { sceneManager } from "../index";
import Subject from "./Subject";
import { scene } from "../SceneManager";
import { addPropToTabs, removeFromTabs, updateTab } from "../helpers/Tabs";
import props from "../constants/props";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { showMessage } from "../Message";
import { quickSave } from "../helpers/SaveManager";
import { Color } from "three";
import { t } from "../helpers/Translate";

class Prop extends Subject {
  constructor({
    id,
    type,
    position,
    scale,
    rotation,
    imageUrl,
    objectInfo,
    title,
    color,
    icon,
    attachedTo = {}
  }) {
    super();

    this.title = title || props.find(prop => prop.id === type).name;

    this.subjectType = "Prop";
    this.type = type;
    this.alive = true;
    this.selected = false;
    this.info = props.find(prop => prop.id === type);
    this.color = color || "#808080";
    this.locked = false;
    this.icon = icon;

    this.fileToUpload = null

    this.id = id || Math.floor(Math.random() * Math.floor(9999));
    this.initialPosition = position;
    this.initialScale = scale;
    this.initialRotation = rotation;
    this.attachedTo = attachedTo;

    this.startLoading(position);

    if (this.info.id == "model") {
      if (objectInfo || imageUrl) {
        if (imageUrl) {
          this.imageUrl = imageUrl;
        }
        this.loadModel(
          objectInfo || imageUrl,
          title,
          position,
          scale,
          rotation
        );
      } else {
        this.uploadInput(".obj", 8, (event, title) => {
          this.loadModel(event.target.result, title, position, scale, rotation);
        });
      }
    } else if (this.info.id == "image") {
      if (objectInfo || imageUrl) {
        if (imageUrl) {
          this.imageUrl = imageUrl;
        }
        this.loadImage(objectInfo || this.imageUrl, title);
      } else {
        this.uploadInput("image/*", 8, (event, title) => {
          this.loadImage(event.target.result, title);
        });
      }
    } else {
      this.loadGeometry(position, scale, rotation);
    }
  }

  get base() {
    return this.object;
  }

  startLoading(position) {
    const loadingGeometry = new THREE.TorusGeometry(10, 3, 16, 100);
    const loadingMaterial = new THREE.MeshBasicMaterial({
      color: new Color("rgb(100%,100%,100%)")
    });
    this.loadingObject = new THREE.Mesh(loadingGeometry, loadingMaterial);
    scene.add(this.loadingObject);
    this.loadingObject.scale.set(0, 0, 0);

    setTimeout(() => {
      if (this.attachedTo && this.attachedTo["modelId"]) {
        console.log(this.attachedTo);
        this.loadingObject.position.set(0, 70, 70);
      } else {
        this.loadingObject.position.copy(
          position || this.getSpawnPoint(this.subjectType)
        );
      }

      const animate = time => {
        if (this.loadingObject) {
          requestAnimationFrame(animate);
          if (this.loadingObject.scale.x < 1) {
            this.loadingObject.scale.x += 0.1;
            this.loadingObject.scale.y += 0.1;
            this.loadingObject.scale.z += 0.1;
          }
          this.loadingObject.rotation.y += 0.05;
          this.loadingObject.rotation.x += 0.05;
          this.loadingObject.rotation.z += 0.05;
        }
      };
      requestAnimationFrame(animate);
    }, 50);
  }

  completeLoading(position = null, scale = null, rotation = null) {
    addPropToTabs(this);
    this.loadingObject.visible = false;
    const finish = () => {
      this.setPosition(position);
      this.setScale(scale);
      this.setRotation(rotation);
      setTimeout(() => {
        sceneManager.sceneProps.push(this);
        scene.remove(this.loadingObject);
        this.loadingObject = null;
        quickSave();
      }, 300);
    };

    if (
      this.attachedTo &&
      this.attachedTo["modelId"] &&
      this.attachedTo["jointName"]
    ) {
      const poll = () => {
        setTimeout(() => {
          if (sceneManager) {
            const character = sceneManager.sceneCharacters.find(
              subject => subject.id === this.attachedTo["modelId"]
            );
            if (character && !character.loading) {
              const joint = character.getJoint(this.attachedTo["jointName"]);
              joint.parent.add(this.object);
              this.attachToJoint(character, joint);

              finish();
            } else {
              console.log("Character not loaded yet. Waiting to try again..");
              poll();
            }
          }
        }, 1000);
      };
      poll();
    } else {
      scene.add(this.object);
      finish();
    }
  }

  flip() {
    this.object.scale.setX(-this.object.scale.x);
  }

  setMaterial() {
    try {
      if (this.object.material) {
        this.object.material.color.set(new THREE.Color(this.color));
      }
      this.object.children.forEach(child => {
        if (child.material.color) {
          child.material.color.set(new THREE.Color(this.color));
        }
      });
    } catch (error) {
      console.error(`Error setting material: ${error}`);
    }
  }

  setImageAsTexture(image) {
    try {
      const loader = new THREE.TextureLoader();
      const mapOverlay = loader.load(image.src);
      const material = new THREE.MeshBasicMaterial({
        map: mapOverlay,
        transparent: true,
        side: THREE.DoubleSide
      });

      if (this.object) {
        this.object.material = material;
      } else {
        const max = 1024;

        let positionY = image.height / 2;
        if (image.width < max && image.height < max) {
          this.object = new THREE.Mesh(
            new THREE.PlaneGeometry(image.width, image.height),
            material
          );
        } else if (image.width > image.height) {
          const ratio = max / image.width;
          this.object = new THREE.Mesh(
            new THREE.PlaneGeometry(max, image.height * ratio),
            material
          );
          positionY = image.height * ratio / 2;
        } else {
          const ratio = max / image.height;
          this.object = new THREE.Mesh(
            new THREE.PlaneGeometry(image.width * ratio, max),
            material
          );
          positionY = max / 2;
        }
        this.object.overdraw = true;

        this.setPosition(
          this.initialPosition || new THREE.Vector3(0, positionY, -300)
        );
        this.setScale(this.initialScale);
        this.setRotation(this.initialRotation);
      }
    } catch (error) {
      console.error(`Error setting image as texture: ${error}`);
    }
  }

  checkIntersects(raycaster) {
    if (!this.object) return;

    let distance = undefined;
    const intersects = raycaster.intersectObject(this.object);

    if (intersects.length > 0) {
      distance = intersects[0].distance;
    } else {
      this.object.children.forEach(child => {
        const singleIntersects = raycaster.intersectObject(child);
        if (singleIntersects.length > 0) {
          distance = singleIntersects[0].distance;
        }
      });
    }

    if (distance) {
      return { object: this, distance: distance, itemClicked: this.object };
    }
  }

  onTap() {
    sceneManager.selectedManager.select(this);
    if (this.locked || !this.object.visible) {
      sceneManager.gizmo.detach();
      return;
    }
    this.selected = true;
    sceneManager.gizmo.updateObject(this.object);
  }

  onSecondaryTap() {
    sceneManager.gizmo.detach();
    if (this.object.parent != scene) {
      this.detachFromJoint();
    } else {
      sceneManager.selectedManager.propToConnect = this;
      showMessage(
        `${t("Select a joint to connect")} ${this.title || t("Prop")}`
      );
      sceneManager.setGizmo("rotate");
    }
  }

  attachToJoint(model, joint, center = false) {
    this.attachedTo = { modelId: model.id, jointName: joint.name };

    joint.attach(this.object);
    sceneManager.selectedManager.propToConnect = null;

    updateTab(this, tab => {
      tab.classList.add("mint");
      tab.querySelector(".attach .on").classList.remove("is-active");
      tab.querySelector(".attach .off").classList.add("is-active");
    });

    if (center) {
      this.setPosition(new THREE.Vector3());
      this.setRotation(new THREE.Vector3());
    }

    console.log("Attached prop to joint");
    quickSave();
  }

  detachFromJoint() {
    scene.attach(this.object);
    sceneManager.selectedManager.propToConnect = null;
    showMessage(
      `${this.title || t("Prop")} ${t(
        "has been disconnected from"
      )} ${this.attachedTo["jointName"].replace("mixamorig", "")}`
    );
    this.attachedTo = null;

    updateTab(this, tab => {
      tab.classList.remove("mint");
      tab.querySelector(".attach .on").classList.add("is-active");
      tab.querySelector(".attach .off").classList.remove("is-active");
    });

    console.log("Detached prop from joint");
  }

  deselect() {
    if (this.object == sceneManager.gizmo.object) {
      sceneManager.gizmo.detach();
      this.selected = false;
    }
  }

  toggleLock(value = null) {
    this.deselect();
    if (value != null) {
      this.locked = value;
    } else {
      this.locked = !this.locked;
    }
  }

  toggleVisibility(value = null) {
    this.deselect();
    if (value != null) {
      this.object.visible = value;
    } else {
      this.object.visible = !this.object.visible;
    }
  }

  delete() {
    sceneManager.gizmo.detach();
    removeFromTabs(this);
    sceneManager.stateManager.storeSceneState();
    this.object.visible = false;
    this.alive = false;
    this.selected = false;
    quickSave();
  }

  reinstate() {
    addPropToTabs(this);
    this.alive = true;
    this.unhover();
    this.object.visible = true;
  }

  duplicate() {
    const newPosition = {
      x: this.getPosition().x + 60,
      y: this.getPosition().y,
      z: this.getPosition().z
    };
    sceneManager.addPropToScene({
      type: this.type,
      position: newPosition,
      scale: this.getScale(),
      rotation: this.getRotation(),
      imageUrl: this.imageUrl,
      objectInfo: this.objectInfo,
      title: this.title,
      color: this.color,
      icon: this.icon
    });
  }

  hover() {
    if (this.object.material) {
      this.object.material.opacity = 0.5;
    }
    this.object.children.forEach(child => {
      if (child.material) {
        child.material.opacity = 0.5;
      }
    });
  }

  unhover() {
    if (this.object.material) {
      this.object.material.opacity = 1;
    }
    this.object.children.forEach(child => {
      if (child.material) {
        child.material.opacity = 1;
      }
    });
  }

  putOnGround() {
    const oldPosition = this.getPosition();
    sceneManager.stateManager.storeSceneState();
    const box = new THREE.Box3().setFromObject(this.object);
    const newYPosition = oldPosition.y - box.min.y;
    const groundedPosition = new THREE.Vector3(
      oldPosition.x,
      newYPosition,
      oldPosition.z
    );
    this.setPosition(groundedPosition);
    quickSave();
  }

  uploadInput(accept, limit, callback) {
    try {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = accept;
      input.style = "display: none";
      document.body.appendChild(input);

      input.addEventListener(
        "change",
        event => {
          if (event.target.files.length < 1) {
            input.remove();
            scene.remove(this.loadingObject);
            return false;
          }
          const fileList = event.target.files;
          if (fileList[0].size > limit * 1000000) {
            showMessage(`${t("File over the")} ${limit}mb ${t("limit")}.`);
            this.value = "";
            input.remove();
            scene.remove(this.loadingObject);
            return false;
          }

          this.fileToUpload = fileList[0];

          const reader = new FileReader();
          reader.readAsDataURL(fileList[0]);
          reader.onload = (() => {
            input.remove();
            return event => callback(event, fileList[0].name);
          })(fileList[0]);
        },
        scene.remove(this.loadingObject),
        false
      );
      input.click();
    } catch (error) {
      console.error(`Error on uploading input: ${error}`);
      scene.remove(this.loadingObject);
    }
  }

  async sendToSpaces(file) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await fetch(
      `https://larry.justsketch.me/userstore/upload/?key=${license.key.trim()}`,
      {
        method: "POST",
        body: formData
      }
    );

    if (response.ok) {
      const data = await response.json();
      this.imageUrl = data["url"];
      console.log(data["url"]);
      this.fileToUpload = null;
      console.log("Uploaded successfully!");
    } else {
      console.error("Failed to upload file.");
    }
  }

  loadGeometry(position, scale, rotation) {
    if (this.info.id == "box" || this.info.name == "Box") {
      this.geometry = new THREE.BoxBufferGeometry(50, 50, 50);
    }
    if (this.info.id == "cylinder" || this.info.name == "Cylinder") {
      this.geometry = new THREE.CylinderBufferGeometry(5, 5, 20, 32);
    }
    if (this.info.id == "cone" || this.info.name == "Cone") {
      this.geometry = new THREE.ConeBufferGeometry(5, 20, 32);
    }
    if (this.info.id == "sphere" || this.info.name == "Sphere") {
      this.geometry = new THREE.SphereBufferGeometry(10, 32, 32);
    }
    if (this.info.id == "torus" || this.info.name == "Torus") {
      this.geometry = new THREE.TorusBufferGeometry(10, 3, 16, 100);
    }

    const material = new THREE.MeshPhongMaterial({
      color: new THREE.Color(this.color),
      specular: 0x00000,
      shininess: 5
    });
    this.object = new THREE.Mesh(this.geometry, material);
    this.object.castShadow = true;
    this.object.receiveShadow = true;

    this.completeLoading(position, scale, rotation);
  }

  loadImage(url, title) {
    try {
      this.image = new Image();
      this.image.src = this.imageUrl
        ? `https://larry.justsketch.me/media/proxy/?url=${url}`
        : url;
      this.objectInfo = url;
      this.title = title;

      this.image.onload = () => {
        this.setImageAsTexture(this.image);
        this.completeLoading();
      };
      this.image.onerror = error => {
        console.error(`Error loading prop (${url}): ${error}`);
        scene.remove(this.loadingObject);
      };
    } catch (error) {
      console.error(`Error loading prop (${url}): ${error}`);
      scene.remove(this.loadingObject);
    }
  }

  loadModel(src, title, position, scale, rotation) {
    try {
      this.objectInfo = src;

      const modelLoader = new OBJLoader();
      modelLoader.setCrossOrigin("anonymous");
      modelLoader.load(
        src,
        rig => {
          this.geometry = rig;
          this.object = rig;
          this.title = title;

          // Sets the scale within a visible range
          if (!scale) {
            const box = new THREE.Box3().setFromObject(this.object);
            const sizeAverage =
              (box.max.x -
                box.min.x +
                (box.max.y - box.min.y) +
                (box.max.z - box.min.z)) /
              3;
            const modifier = 40 / sizeAverage;
            scale = new THREE.Vector3(modifier, modifier, modifier);
          }

          this.setMaterial();
          this.completeLoading(position, scale, rotation);
        },
        xhr => {
          if (xhr.lengthComputable) {
            const percentComplete = xhr.loaded / xhr.total;
            const red = Math.ceil(255 - percentComplete * 255);
            const green = Math.ceil(255 - percentComplete * 8);
            const blue = Math.ceil(255 - percentComplete * 0);
            this.loadingObject.material.color = new THREE.Color(
              `rgb(${red}, ${green}, ${blue})`
            );
          }
        },
        error => {
          setTimeout(() => {
            scene.remove(this.loadingObject);
            this.loadingObject = null;
          }, 200);
          showMessage(`${t("Failed to load")} ${this.title}`);
          console.error(`Error loading prop (${src}): ${error}`);
        }
      );
    } catch (error) {
      setTimeout(() => {
        scene.remove(this.loadingObject);
        this.loadingObject = null;
      }, 200);
      showMessage(`${t("Failed to load")} ${this.title}`);
      console.error(`Error loading prop (${src}): ${error}`);
    }
  }
}

export default Prop;
