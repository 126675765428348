require("babel-core/register");
require("babel-polyfill");

import { api, store } from "./index";
import { closeAllMenus } from "./helpers/Menus";
import { showMessage } from "./Message";
import { t } from "./helpers/Translate";

class License {
  constructor() {
    this.valid = false;
    this.key = localStorage.getItem("licence_key") || null;

    if (this.key) {
      this.validate(this.key);
    } else {
      document.querySelector("#upgrade-button").style.display = "inline-flex";
    }
  }

  async validate(key) {
    if (!key) return;
    if (this.isMonsterValid(this.hash(key))) {
      setTimeout(
        () => this.validKey(key, JSON.parse(localStorage.getItem("user_data"))),
        300
      );
    } else {
      api.validateLicenseKey(key).then(data => {
        if (data) {
          showMessage(
            "<b>💃 Welcome to JustSketchMe Pro! 🕺</b> <br> All of the features and models have been unlocked!",
            4000
          );
          this.validKey(key, data);
        } else {
          this.invalidKey();
        }
      });
    }
  }

  validKey(key, data) {
    this.valid = true;
    this.key = key;

    this.setMonster(this.hash(key), 60);
    localStorage.setItem("licence_key", key);
    localStorage.setItem("user_data", JSON.stringify(data));
    store.setState({ user: data });
    document.querySelector("#upgrade-button").style.display = "none";
    closeAllMenus();
    this.getAllInfo(key);
  }

  invalidKey() {
    this.valid = false;
    localStorage.removeItem("licence_key");
    localStorage.removeItem("user_data");
    this.key = null;
    document.querySelector("#upgrade-button").style.display = "inline-flex";
  }

  getAllInfo(key) {
    api.getLibrary(key).then(data => {
      if (data) {
        store.setState({ poseLibrary: data });
        this.valid = true;
        this.key = key;
      }
    });
    api.getScenes(key).then(data => {
      if (data) {
        store.setState({ savedScenes: data });
      }
    });
    api.getPoses(key).then(data => {
      if (data) {
        store.setState({ savedPoses: data });
      }
    });
    api.getPropPacks(key).then(data => {
      if (data) {
        store.setState({ propPacks: data });
      }
    });
  }

  check() {
    closeAllMenus();
    if (!this.valid) {
      openMenu("#upgrade-button", "#upgrade-menu");
    }
    return this.valid;
  }

  prompt() {
    const key = prompt("Enter your license key");
    if (key) {
      this.validate(key);
    }
  }

  clear() {
    localStorage.removeItem("licence_key");
    location.reload();
  }

  setMonster(value, length) {
    const date = new Date();
    date.setTime(date.getTime() + length * 24 * 60 * 60 * 1000);
    let expires = "expires=" + date.toUTCString();
    document.cookie = "monster=" + value + ";" + expires + ";path=/";
  }

  isMonsterValid(value) {
    let decodedCookie = decodeURIComponent(document.cookie);
    return (
      decodedCookie.substring(decodedCookie.indexOf("=") + 1, 999) == value
    );
  }

  hash(string) {
    let hash = 0;

    if (string.length == 0) {
      return hash;
    }
    for (let i = 0; i < string.length; i++) {
      let ch = string.charCodeAt(i);
      hash = (hash << 5) - hash + ch;
      hash = hash & hash;
    }
    return hash;
  }
}

export default License;
