import { loader } from './index';
import { ColladaExporter } from 'three/examples/jsm/exporters/ColladaExporter';
import { OBJExporter } from 'three/examples/jsm/exporters/OBJExporter';

class ModelExporter {
    constructor() {
        this.link = document.createElement('a');
        this.link.style.display = 'none';
        document.body.appendChild(this.link);
    }

    exportCollada(object) {
        const exporter = new ColladaExporter();
        const result = exporter.parse(object);

        this.saveString(result.data, 'jsm_model.dae', 'application/collada+xml');

        result.textures.forEach(tex => {
            this.saveArrayBuffer(tex.data, `${tex.name}.${tex.ext}`);
        });
    }

    exportObj(obj) {
        const exporter = new OBJExporter();
        const result = exporter.parse(obj);
        this.saveString(result, 'jsm_model.obj', 'text/plain');
    }

    exportGLTF(input) {
        const gltfExporter = new GLTFExporter();

        gltfExporter.parse(input, result => {

            if (result instanceof ArrayBuffer) {

                this.saveArrayBuffer(result, 'scene.glb');

            } else {

                const output = JSON.stringify(result, null, 2);
                this.saveString(output, 'scene.gltf');

            }

        });

    }

    save(blob, filename) {
        loader.startLoading('exportingModel', 'Downloading your model...');
        this.blobToDataURL(blob, result => {
            this.link.href = result
            this.link.download = filename;
            this.link.click();
            loader.stopLoading('exportingModel');
        })
    }

    blobToDataURL(blob, callback) {
        var fileReader = new FileReader();
        fileReader.onload = function(e) {callback(e.target.result);}
        fileReader.readAsDataURL(blob);
    }

    saveString(text, filename, mimeType) {
        this.save(new Blob([text], { type: mimeType }), filename);
    }

    saveArrayBuffer(arrayBuffer, filename) {
        this.save(new Blob([arrayBuffer], { type: 'application/octet-stream' }), filename);
    }
}

export default ModelExporter;