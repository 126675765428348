import { TransformControls } from './TransformControls';
import { scene, camera, cameraLocked, orbitControl, mirrorMode } from '../SceneManager';
import { sceneManager } from '../index';
import { quickSave } from '../helpers/SaveManager';

class Control {
	constructor(object, type, space = "local") {
		this.object = object
		this.transformControl = new TransformControls(camera, canvas);
		this.isAttached = false;

		if (object) {
			this.transformControl.attach(object);
		}

		this.transformControl.setMode(type);
		this.transformControl.setSpace(space);

		scene.add(this.transformControl);

		this.dragging = false;

		const startDrag = () => {
			orbitControl.enabled = false;
			this.dragging = true;
			sceneManager.stateManager.storeSceneState();
			this.startDraggingLoop();
		}

		const endDrag = () => {
			orbitControl.enabled = !cameraLocked;
			this.dragging = false;
			quickSave()
		}

		this.transformControl.addEventListener('mouseDown', event => {
			startDrag()
		});

		this.transformControl.addEventListener('mouseUp', event => {
			endDrag()
		});

		this.transformControl.addEventListener('touchstart', () => {
			startDrag()
		});
		this.transformControl.addEventListener('touchend', () => {
			endDrag()
		});
	}

	startDraggingLoop() {
		if (this.dragging) {
			// Mirror posing mode
			if (mirrorMode && sceneManager.selectedManager.subject.subjectType == "Character") {
				const character = sceneManager.selectedManager.subject;
				const selectedJoint = this.object;

				if (selectedJoint) {
					if (sceneManager.activeGizmo === "rotate") {
						character.mirrorRotation(selectedJoint);
					} else if (sceneManager.activeGizmo == "scale") {
						character.mirrorScale(selectedJoint);
					}
				}
			}

			requestAnimationFrame(() => this.startDraggingLoop());
		}
	}


	updateObject(newObject) {
		this.transformControl.detach();
		this.transformControl.attach(newObject);

		newObject.renderOrder = -1;
		this.object = newObject;
		this.isAttached = true;
	}

	attach() {
		this.transformControl.attach();
		this.isAttached = true;
	}

	detach() {
		this.transformControl.detach();
		this.isAttached = false;
	}

	setMode(mode) {
		if (mode == 'hidden') {
			this.detach();
			sceneManager.directionalLight.setVisibility(false);
		} else {
			this.transformControl.setMode(mode);
			sceneManager.directionalLight.setVisibility(true);

			if (mode == 'scale') {
				// this.transformControl.showX = false
				// this.transformControl.showY = false
				// this.transformControl.showZ = false
			}
		}
	}

	setVisibleAxis(x=true, y=true, z=true) {
		this.transformControl.showX = x;
		this.transformControl.showY = y;
		this.transformControl.showZ = z;
	}
}

export const gizmos = {
	TRANSLATE: 'translate',
	SCALE: 'scale',
	ROTATE: 'rotate',
	HIDDEN: 'hidden',
	NONE: 'none',
}

export default Control;