import { showMessage } from "../Message"
export const tier1 = ['AI', 'AT', 'AU', 'AX', 'BB', 'BM', 'CA', 'CH', 'DK', 'FI', 'FM', 'GB', 'IE', 'IL', 'IS', 'JP', 'KY', 'LU', 'MH', 'NL', 'NO', 'NZ', 'PR', 'SE', 'SS', 'TC', 'TV', 'US', 'VU']
export const tier2 = ['AD', 'AG', 'AW', 'BE', 'BS', 'BZ', 'CG', 'CN', 'CW', 'CY', 'DE', 'DM', 'EE', 'ES', 'FR', 'GR', 'HK', 'IT', 'KI', 'KN', 'KR', 'LC', 'MO', 'MT', 'NR', 'PG', 'PT', 'PW', 'QA', 'SB', 'SG', 'SI', 'SK', 'SM', 'SX', 'TO', 'UY', 'WS', 'ZW']
export const tier3 = ['AE', 'AL', 'AR', 'AS', 'BA', 'BG', 'BH', 'BN', 'BR', 'BW', 'CD', 'CF', 'CI', 'CL', 'CM', 'CR', 'CV', 'CZ', 'DJ', 'DO', 'EC', 'FJ', 'GA', 'GD', 'GN', 'GQ', 'GT', 'HN', 'HR', 'HT', 'HU', 'IQ', 'JM', 'JO', 'KM', 'KW', 'LR', 'LS', 'LT', 'LV', 'MA', 'ME', 'MV', 'MX', 'NA', 'NE', 'OM', 'PA', 'PE', 'PL', 'PS', 'RO', 'RS', 'SA', 'SC', 'SN', 'ST', 'SV', 'SZ', 'TD', 'TG', 'TM', 'TT', 'VC', 'YE', 'ZA']
export const tier4 = ['AF', 'AM', 'AO', 'AZ', 'BD', 'BF', 'BI', 'BJ', 'BO', 'BT', 'BY', 'CO', 'DZ', 'EG', 'ER', 'ET', 'GE', 'GH', 'GM', 'GW', 'GY', 'ID', 'IN', 'KE', 'KG', 'KH', 'KZ', 'LA', 'LB', 'LK', 'LY', 'MD', 'MG', 'MK', 'ML', 'MM', 'MN', 'MR', 'MU', 'MW', 'MY', 'MZ', 'NG', 'NI', 'NP', 'PH', 'PK', 'PY', 'RU', 'RW', 'SL', 'SO', 'SR', 'TH', 'TJ', 'TL', 'TN', 'TR', 'TZ', 'UA', 'UG', 'UZ', 'VN', 'ZM']
export const unknown = ['AQ', 'BQ', 'BV', 'CC', 'CK', 'CU', 'CW', 'CX', 'EH', 'FK', 'FO', 'GF', 'GG', 'GI', 'GL', 'GP', 'GS', 'GU', 'HM', 'IM', 'IO', 'IR', 'JE', 'KP', 'KY', 'LB', 'LI', 'MC', 'MF', 'MP', 'MQ', 'MS', 'NC', 'NF', 'NU', 'PF', 'PM', 'PN', 'PS', 'RE', 'SD', 'SH', 'SJ', 'SX', 'SY', 'TF', 'TK', 'UM', 'VA', 'VE', 'VG', 'VI', 'WF', 'XK', 'YT']


export const showDiscountPopup = () => {
    fetch('https://geolocation-db.com/json/')
        .then(response => response.json())
        .then(response => {

            let discount = 0
            let promoCode = ""

            if (tier2.indexOf(response.country_code) > 0) {
                discount = 15
                promoCode = "PASTEL"
                displayPopup(response, discount, promoCode)
            }
            if (tier3.indexOf(response.country_code) > 0) {
                discount = 30
                promoCode = "FRESCO"
                displayPopup(response, discount, promoCode)
            }
            if (tier4.indexOf(response.country_code) > 0) {
                discount = 60
                promoCode = "INK"
                displayPopup(response, discount, promoCode)
            }
        })
}

const displayPopup = (response, discount, promoCode) => {
    // showMessage(
    //     `Hey, it looks like you're from ${response.country_name} ${getFlagEmoji(response.country_code)}<br>
    // We support <b>Purchasing Power Parity</b> to make <b>JustSketchMe Pro</b> affordable.<br>
    // Use the promo code <b class="selectable">${promoCode}</b> to get a <b>${discount}% discount</b> when you upgrade
    // ${window.isWebView ? 'on the website' : ''}`,
    //     300000,
    //     5000
    // )
    document.getElementById('ppp').innerHTML = `<span class="hl chilled-pink">Hey, it looks like you're from ${response.country_name} ${getFlagEmoji(response.country_code)}</span><br>
    We support <b>Purchasing Power Parity</b> to make <b>JustSketchMe Pro</b> affordable.<br>
    Use the promo code <b class="selectable">${promoCode}</b> to get a <b>${discount}% discount</b> when you upgrade
    ${window.isWebView ? 'on the website' : ''}`;
}

const getFlagEmoji = (countryCode) => {
    const codePoints = countryCode.toUpperCase().split("").map((char) => 127397 + char.charCodeAt(0));
    return String.fromCodePoint(...codePoints);
}