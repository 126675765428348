export const translateTextualContent = () => {
    const recurseDOM = scope => {
		let i = 0, nodes, node;
		if(scope.childNodes)
		{
			nodes = scope.childNodes;
			for(i; i<nodes.length; i++)
			{
                node = nodes[i];
                
				if(node.nodeType === 3)
				{
					const data = node.data.trim()
                    if (data) {
                        node.data = t(node.data)
                    }    
                }
                
				if(node.childNodes)
				{
					// loop through child nodes if child nodes are found
					recurseDOM(node);
				}
				node = null;
			}
			nodes = null;
		}   
    }
    
    recurseDOM(document.body);
}

export const t = (lookup) => {
    if (localStorage.getItem('lang') == 'pt') {
        switch (lookup) {
            // Tooltips
            case 'Add characters to scene': return 'Adicionar personagens à cena'
            case 'Shapes and props': return 'Formas e figurinos'
            case 'Upload image/model': return 'Upload de imagem/modelo'
            case 'Undo action (Ctrl+Z)': return 'Desfazer ação (Ctrl+Z)'
            case 'Redo action (Ctrl+Shift+Z)': return 'Refazer ação (Ctrl+Shift+Z)'
            case 'Place on ground (J)': return 'Por no chão (J)'
            case 'Toggle lock camera (L)': return 'Alternar bloqueio de câmera (L)'
            case 'Toggle mirror posing mode (J)': return 'Alternar posicionamento espelhado (J)'
            case 'Options': return 'Opções'
            case 'Tutorial': return 'Tutorial'
            case 'Manipulate joints (Q)': return 'Manipular articulações (Q)'
            case 'Move (W)': return 'Mover (W)'
            case 'Scale (E)': return 'Escala (E)'
            case 'Hide tools (R)': return 'Esconder ferramentas (R)'
            case 'Poses': return 'Poses'
            case 'Hand poses': return 'Poses de mão '
            case 'Scenes': return 'Cenas'
            case 'Screenshot or export': return 'Capturar tela ou exportar'
            case 'Clear scene': return 'Limpar cena'

            // Tab tooltips
            case 'Select': return 'Selecionar'
            case 'Duplicate': return 'Duplicata'
            case 'Flip': return 'Flip'
            case 'Hide toggle': return 'Esconder ou mostrar'
            case 'Lock toggle': return 'Travar ou destravar'
            case 'Delete': return 'Excluir'
            case 'Attach to character': return 'Anexar a um personagem'
            case 'Put on ground': return 'Colocar objeto no chão'

            // Tutorial
            case 'Want to use JustSketchMe like a pro?': return 'Quer usar o JustSketchMe como um profissional?'
            case 'Check out our': return 'Confira nossos'
            case 'tutorial videos': return 'vídeos tutoriais'
            case 'Gotcha!': return 'Entendi!'
            case 'Tap the': return 'Toque no'
            case 'button in the top right to re-open this info.': return 'botão no canto superior direito para reabrir o tutorial.'
            case "Don't show this again": return 'Não mostre isso novamente'

            case 'Add characters': return 'Adicionar personagens'
            case 'Set color, duplicate, flip, delete & hide joints': return 'Escolher cor, duplicar, girar, excluir e esconder articulações'
            case 'Rotate joints': return 'Manipular articulações'
            case 'Move objects': return 'Mover objetos'
            case 'Scale objects': return 'Redimensionar objetos'
            case 'Hide all tools': return 'Ocultar ferramentas'
            case 'Save camera position': return 'Salvar posição da câmera'
            case 'Save (hold) or restore (tap) camera position': return 'Salvar (pressionar) ou restaurar (tocar) posição da câmera'            
            case 'Lock camera': return 'Bloquear câmera'
            case 'Mirror posing mode': return 'Posicionamento espelhado'
            case 'Settings': return 'Opções'
            case 'Hide tips': return 'Cultar este tutorial'
            case 'Take screenshot': return 'Tirar captura de tela'
            
            // Character menu
            case 'Characters': return 'Personagens'
            case 'Base': return 'Base'
            case 'Stylised': return 'Estilizado'
            case 'Realistic': return 'Realista'
            case 'Fantasy': return 'Fantasia'
            case 'Other': return 'Outros'
            case 'Male': return "Homem"
            case 'Female': return "Mulher"
            case 'Teen female': return "Adolescente mulher"
            case 'Teen male': return "Adolescente homem"
            case 'Tween female': return 'Pré-adolescente mulher'
            case 'Tween male': return 'Pré-adolescente homem'
            case 'Child (older)': return 'Criança mais velha'
            case 'Child (younger)': return 'Criança mais jovem'
            case 'Baby': return 'Bebê'
            case 'Anime female v1': return 'Anime mulher v1'
            case 'Anime male v1': return 'Anime homem v1'
            case 'Anime female v2': return 'Anime mulher v2'
            case 'Anime male v2': return 'Anime homem v2'
            case 'Stylized female': return 'Mulher estilizada'
            case 'Stylized male': return 'Homem estilizado'
            case 'Chibi male': return 'Chibi homem'
            case 'Chibi female': return 'Chibi mulher'
            case 'Superhero male': return 'Super-herói homem'
            case 'Superhero female': return 'Super-herói mulher'
            case 'Chubby male': return 'Homem gordinho'
            case 'Chubby female': return 'Mulher gordinha'
            case 'Skinny male': return 'Homem magro'
            case 'Skinny female': return 'Mulher magra'
            case 'Muscular male': return 'Homem musculoso'
            case 'Muscular female': return 'Mulher musculosa'
            case 'Mermaid': return 'Sereia'
            case 'Merman': return 'Tritão'
            case 'Skeleton': return 'Esqueleto'
            case 'Dragon': return 'Dragão'
            case 'Lycan male': return 'Lobisomem'
            case 'Lycan female': return 'Lobismulher'
            case 'Art mannequin': return 'Manequim de arte'
            case 'Dog': return 'Cão'
            case 'Cat': return 'Gato'
            case 'Female v1': return 'Mulher v1'
            case 'Male v1': return 'Homem v1'
            case 'Large male': return 'Homem gordinho'
            case 'Large female': return 'Mulher gordinha'

            // Prop menu
            case 'Shapes and props': return 'Formas e figurinos'
            case 'Basic shapes': return 'Formas básicas'
            case 'Box': return 'Caixa'
            case 'Cylinder': return 'Cilindro'
            case 'Sphere': return 'Esfera'
            case 'Cone': return 'Cone'
            case 'Torus': return 'Torus'

            // Upload menu
            case 'Upload objects': return 'Upload de objetos'
            case 'Upload image': return 'Upload de imagem'
            case 'Upload prop (.obj)': return 'Upload de modelo (.obj)'
            case 'Uploading may have issues with broken 3D models. If it gets funky reload the page.': return 'O upload pode ter problemas com modelos 3D quebrados. Se ficar funky recarregue a página.'

            // Upgrade menu
            case 'Upgrade': return 'Atualização'
            case 'Take your art to the next level!': return 'Leve sua arte para o próximo nível!'
            case 'Get JustSketchMe Pro!': return 'Obtenha o JustSketchMe Pro!'
            case 'Already have a license key?': return 'Já tem uma chave de licença?'
            case 'License key...': return 'Chave de licença...'
            case 'License key': return 'Chave de licença'
            case 'Activate': return 'Ativar'
            case 'https://justsketchme.lemonsqueezy.com/checkout/buy/24ec4be2-997a-4ed0-96e7-551c14dc1b78': return 'https://justsketchme.lemonsqueezy.com/checkout/buy/b5b4c8b8-25ba-4ae7-929a-14e313d4a291'

            case 'Unlock all': return 'Desbloqueie todos os'
            case '30+ models': return '30+ modelos'
            case '200+ pre-set poses': return '200+ poses'
            case 'props': return 'objetos úteis'
            case 'saving': return 'armazenamento em nuvem'
            case 'and more!': return 'e mais!'

            // Options menu
            case 'Options': return 'Opções'
            case 'Scene': return 'Centa'
            case 'Apps': return 'Apps'
            case 'Account': return 'Conta'
            case '🌚 Dark mode': return '🌚 Modo escuro'
            case '🔆 Ambient lighting': return '🔆 Iluminação ambiente'
            case '💡 Spot lighting': return '💡 Iluminação local'
            case '👤 Hard shadows': return '👤 Sombras duras '
            case '🖊 Outline effect': return '🖊 Efeito contorno '
            case '🌐 Floor grid': return '🌐 Grid do piso'
            case '🎥 Field of view': return '🎥 Campo de visão'
            case 'Reset light': return 'Redefinir luz'
            case '🔄 Reset app settings': return '🔄 Redefinir a configuração do aplicativo'
            case '🤖 Update app': return '🤖 Atualizar aplicativo'
            case '🪵 Log out': return '🪵 Sair'
            case '🖥 Activate on other devices with:': return '🖥 Ativar em outros dispositivos:'
            case 'Up to 3 devices': return 'Apenas 3 dispositivos'
            case '🐞 Report a bug': return '🐞 Relatar um erro'
            case '🧜‍♂️ Suggest a feature': return '🧜‍♂️ Sugerir uma característica'
            case '📜 Documentation': return '📜 Documentação'
            case '❓ FAQ': return '❓ FAQ'
            case '💳 Manage subscription': return '💳 Gerenciar assinatura'

            // Pose menu
            case 'Poses': return 'Poses'
            case 'Saved poses': return 'Poses salvas'
            case 'Pose library': return 'Biblioteca'
            case 'Download pose': return 'Download'
            case 'Save pose': return 'Salvar pose'
            case 'Open pose': return 'Abrir'
            case 'Neutral': return 'Neutro'
            case 'T pose': return 'T pose'
            case 'Save': return 'Salvar'
            case 'Category (optional)': return 'Categoria (opcional)'
            case 'Pose name': return 'Nome de pose'

            // Hand pose menu
            case 'Hand pose library': return 'Poses de mão'
            case 'Left': return 'Esq.'
            case 'Right': return 'Dir.'

            // Scene menu
            case 'Scenes': return 'Cenas'
            case 'Save scene': return 'Salvar cena'
            case 'Download scene': return 'Download'
            case 'Import scene': return 'Importar'
            case 'Name': return 'Nome'
            case 'Scene name': return 'Nome de cena'

            // Export menu
            case '🖼️ Take screenshot': return '🖼️ Capturar tela'
            case 'Export model as .dae': return 'Exportar modelo como .dae'
            case 'Export model as .obj': return 'Exportar modelo como .obj'

            // Messages
            case 'Internet connection required to validate key': return 'É necessária uma conexão de internet para validar a chave'
            case 'Checking license key...': return 'Verificando a chave da licença...'
            case 'This license key has expired': return 'Esta chave de licença expirou'
            case 'This license key does not exist or has expired': return 'Esta chave de licença não existe ou expirou'
            case 'No character to apply pose to': return 'Nenhum personagem para aplicar a pose'
            case '<b>💃 Welcome to JustSketchMe Pro! 🕺</b> <br> All of the features and models have been unlocked!': return '<b>💃 Bem-vindo ao JustSketchMe Pro! 🕺</b> <br> Todos os recursos e modelos foram desbloqueados!'
            case 'Internet connection required for cloud features': return 'É necessária uma conexão de internet para acessar os recursos na nuvem'
            case 'updated': return 'atualizada'
            case 'saved': return 'salva'
            case 'Overwrite': return 'Substituir'
            case 'Overwrite with': return 'Substituir por'
            case 'File over the': return 'Arquive acima do limite de'
            case 'limit': return ''
            case 'Failed to load': return 'Falha ao carregar'
            case 'Prop': return 'modelo'
            case 'Move to joint?': return 'Mover para articulação?'
            case 'is now attached to': return 'agora está ligado à'
            case 'Select a joint to connect': return 'Selecione uma articulação para conectar o'
            case 'has been disconnected from': return 'foi desconectado da'
            case 'Failed to load': return 'Falha ao carregar'
            case 'There are too many users using this license key': return 'Há muitos usuários usando esta chave de licença'
            case 'Delete?': return 'Confirmar excluir?'
            case 'Are you sure you want to clear the scene?': return 'Você tem certeza de que quer desobstruir a cena?'
            case 'Exit hand editor': return "Sair do editor de mãos"
            case 'copied to clipboard': return 'copiado para a área de transferência'

            default: lookup
        }
    }

    if (localStorage.getItem('lang') == 'es') {
        switch (lookup) {
            // Tooltips
            case 'Add characters to scene': return 'Añadir personajes a la escena'
            case 'Shapes and props': return 'Formas y accesorios'
            case 'Upload image/model': return 'Cargar imagen/modelo'
            case 'Undo action (Ctrl+Z)': return 'Deshacer acción (Ctrl+Z)'
            case 'Redo action (Ctrl+Shift+Z)': return 'Rehacer acción (Ctrl+Mayús+Z)'
            case 'Place on ground (J)': return 'Colocar en el suelo (J)'
            case 'Toggle lock camera (L)': return 'Bloquear/Desbloquear cámara (L)'
            case 'Toggle mirror posing mode (J)': return 'Alternar posado espejo (J)'
            case 'Options': return 'Opciones'
            case 'Tutorial': return 'Tutorial'
            case 'Manipulate joints (Q)': return 'Manipular articulaciones (Q)'
            case 'Move (W)': return 'Mover (W)'
            case 'Scale (E)': return 'Escalar (E)'
            case 'Hide tools (R)': return 'Ocultar herramientas (R)'
            case 'Poses': return 'Poses'
            case 'Hand poses': return 'Poses de mano'
            case 'Scenes': return 'Escenas'
            case 'Screenshot or export': return 'Captura de pantalla o exportación'
            case 'Clear scene': return 'Borrar escena'

            // Tab tooltips
            case 'Select': return 'Seleccionar'
            case 'Duplicate': return 'Duplicar'
            case 'Flip': return 'Voltear'
            case 'Hide toggle': return 'Ocultar'
            case 'Lock toggle': return 'Bloquear'
            case 'Delete': return 'Borrar'
            case 'Attach to character': return 'Adjuntar al personaje'
            case 'Put on ground': return 'Poner en el suelo'

            // Tutorial
            case 'Want to use JustSketchMe like a pro?': return '¿Desear usar JustSketchMe como un profesional?'
            case 'Check out our': return 'Echa un vistazo a nuestros'
            case 'tutorial videos': return 'videotutoriales'
            case 'Gotcha!': return '¡Entendido!'
            case 'Tap the': return 'Toca el botón'
            case 'button in the top right to re-open this info.': return 'situado en la parte superior derecha para volver a ver esta información.'
            case "Don't show this again": return 'No volver a mostrar'

            case 'Add characters': return 'Añadir personajes'
            case 'Set color, duplicate, flip, delete & hide joints': return 'Establecer el color, duplicar, voltear, eliminar y ocultar articulaciones'
            case 'Rotate joints': return 'Rotar articulaciones'
            case 'Move objects': return 'Mover objetos'
            case 'Scale objects': return 'Escalar objetos'
            case 'Hide all tools': return 'Ocultar herramientas'
            case 'Save camera position': return 'Posición de la cámara'
            case 'Save (hold) or restore (tap) camera position': return 'Guardar (mantener) o restaurar (tocar) posición de la cámara'
            case 'Lock camera': return 'Bloquear cámara'
            case 'Mirror posing mode': return 'Modo de posado espejo'
            case 'Settings': return 'Ajustes'
            case 'Hide tips': return 'Cerrar este tutorial'
            case 'Take screenshot': return 'Tomar captura de pantalla'
            
            // Character menu
            case 'Characters': return 'Personajes'
            case 'Base': return 'Base'
            case 'Stylised': return 'Estilizado'
            case 'Realistic': return 'Realista'
            case 'Fantasy': return 'Fantástico'
            case 'Other': return 'Otros'
            case 'Male': return "Hombre"
            case 'Female': return "Mujer"
            case 'Teen female': return "Adolescente femenino"
            case 'Teen male': return "Adolescente masculino"
            case 'Tween female': return 'Preadolescente femenino'
            case 'Tween male': return 'Preadolescente masculino'
            case 'Child (older)': return 'Niño (mayor)'
            case 'Child (younger)': return 'Niño (menor)'
            case 'Baby': return 'Bebé'
            case 'Anime female v1': return 'Anime femenino v1'
            case 'Anime male v1': return 'Anime masculino v1'
            case 'Anime female v2': return 'Anime femenino v2'
            case 'Anime male v2': return 'Anime masculino v2'
            case 'Stylized female': return 'Mujer estilizada'
            case 'Stylized male': return 'Hombre estilizado'
            case 'Chibi male': return 'Chibi masculino'
            case 'Chibi female': return 'Chibi femenino'
            case 'Superhero male': return 'Superhéroe'
            case 'Superhero female': return 'Superheroína'
            case 'Chubby male': return 'Hombre rechoncho'
            case 'Chubby female': return 'Mujer rechoncha'
            case 'Skinny male': return 'Hombre delgado'
            case 'Skinny female': return 'Mujer delgada'
            case 'Muscular male': return 'Hombre musculoso'
            case 'Muscular female': return 'Mujer musculosa'
            case 'Mermaid': return 'Sirena'
            case 'Merman': return 'Hombre sirena'
            case 'Skeleton': return 'Esqueleto'
            case 'Dragon': return 'Dragón'
            case 'Lycan male': return 'Licántropo'
            case 'Lycan female': return 'Licántropa'
            case 'Art mannequin': return 'Maniquí artístico'
            case 'Dog': return 'Perro'
            case 'Cat': return 'Gato'
            case 'Female v1': return 'Mujer v1'
            case 'Male v1': return 'Hombre v1'
            case 'Large male': return 'Hombre grande'
            case 'Large female': return 'Mujer grande'

            // Prop menu
            case 'Shapes and props': return 'Formas y accesorios'
            case 'Basic shapes': return 'Formas básicas'
            case 'Box': return 'Caja'
            case 'Cylinder': return 'Cilindro'
            case 'Sphere': return 'Esfera'
            case 'Cone': return 'Cono'
            case 'Torus': return 'Toro'
            case '': return ''

            // Upload menu
            case 'Upload objects': return 'Cargar objetos'
            case 'Upload image': return 'Cargar imagen'
            case 'Upload prop (.obj)': return 'Cargar accesorio (.obj)'
            case 'Uploading may have issues with broken 3D models. If it gets funky reload the page.': return 'Pueden surgir problemas con la subida de modelos 3D seccionados. Si no va bien, vuelve a cargar  la página.'

            // Upgrade menu
            case 'Upgrade': return 'Mejorar'
            case 'Take your art to the next level!': return '¡Lleva tu arte un paso más allá!'
            case 'Get JustSketchMe Pro!': return '¡Hazte con JustSketchMe Pro!'
            case 'Already have a license key?': return '¿Ya tienes una clave de licencia?'
            case 'License key...': return 'Clave de licencia...'
            case 'License key': return 'Clave de licencia'
            case 'Activate': return 'Activar'
            case 'https://justsketchme.lemonsqueezy.com/checkout/buy/24ec4be2-997a-4ed0-96e7-551c14dc1b78': return 'https://justsketchme.lemonsqueezy.com/checkout/buy/254e3a2d-7c36-4cff-adff-035ac55e4053'

            case 'Unlock all': return 'Desbloquear todo'
            case '30+ models': return 'más de 30 modelos'
            case '200+ pre-set poses': return 'más de 200 poses predefinidas'
            case 'props': return 'accesorios'
            case 'saving': return 'guardar'
            case 'and more!': return '¡y mucho más!'

            // Options menu
            case 'Options': return 'Opciones'
            case 'Scene': return 'Escena'
            case 'Apps': return 'Apps'
            case 'Account': return 'Cuenta'
            case '🌚 Dark mode': return '🌚 Modo oscuro'
            case '🔆 Ambient lighting': return '🔆 Iluminación ambiental'
            case '💡 Spot lighting': return '💡 Iluminación por puntos'
            case '👤 Hard shadows': return '👤 Luz dura'
            case '🖊 Outline effect': return '🖊 Efecto contorno'
            case '🌐 Floor grid': return '🌐 Rejilla en suelo'
            case '🎥 Field of view': return '🎥 Campo de visión'
            case 'Reset light': return 'Restaurar luz'
            case '🔄 Reset app settings': return '🔄 Restablecer ajustes'
            case '🤖 Update app': return '🤖 Actualizar app'
            case '🪵 Log out': return '🪵 Cerrar sesión'
            case '🖥 Activate on other devices with:': return '🖥 Activar en otros dispositivos con:'
            case 'Up to 3 devices': return 'Hasta 3 dispositivos'
            case '🐞 Report a bug': return '🐞 Informar de un error'
            case '🧜‍♂️ Suggest a feature': return '🧜‍♂️ Sugerir una funcionalidad'
            case '📜 Documentation': return '📜 Documentación'
            case '❓ FAQ': return '❓ Preguntas frecuentes'
            case '💳 Manage subscription': return '💳 Gestionar suscripción'

            // Pose menu
            case 'Poses': return 'Poses'
            case 'Saved poses': return 'Poses guardadas'
            case 'Pose library': return 'Biblioteca'
            case 'Download pose': return 'Descargar'
            case 'Save pose': return 'Guardar pose'
            case 'Open pose': return 'Abrir'
            case 'Neutral': return 'Neutral'
            case 'T pose': return 'Pose en T'
            case 'Save': return 'Guardar'
            case 'Category (optional)': return 'Categoría (opcional)'
            case 'Pose name': return 'Nombre de la pose'

            // Hand pose menu
            case 'Hand pose library': return 'Poses de manos'
            case 'Left': return 'Izq.'
            case 'Right': return 'Der.'

            // Scene menu
            case 'Scenes': return 'Escenas'
            case 'Save scene': return 'Guardar escena'
            case 'Download scene': return 'Descargar'
            case 'Import scene': return 'Importar'
            case 'Name': return 'Nombre'
            case 'Scene name': return 'Nombre de la escena'

            // Export menu
            case '🖼️ Take screenshot': return '🖼️ Hacer captura de pantalla'
            case 'Export model as .dae': return 'Exportar modelo como .dae'
            case 'Export model as .obj': return 'Exportar modelo como .obj'

            // Messages
            case 'Internet connection required to validate key': return 'Se requiere conexión a Internet para validar la clave'
            case 'Checking license key...': return 'Verificando la clave de licencia...'
            case 'This license key has expired': return 'Esta clave de licencia ha expirado'
            case 'This license key does not exist or has expired': return 'Esta clave de licencia no existe o ha expirado'
            case 'No character to apply pose to': return 'No hay personaje al que aplicar la pose'
            case '<b>💃 Welcome to JustSketchMe Pro! 🕺</b> <br> All of the features and models have been unlocked!': return '<b>💃¡Bienvenido a JustSketchMe Pro! 🕺</b> <br> ¡Todas las funcionalidades y modelos han sido desbloqueados!'
            case 'Internet connection required for cloud features': return 'Se requiere conexión a Internet para acceder a las funciones de la nube'
            case 'updated': return 'actualizado'
            case 'saved': return 'guardado'
            case 'Overwrite': return 'Sobrescribir'
            case 'Overwrite with': return 'Sobrescribir con'
            case 'File over the': return 'Archivo por encima del'
            case 'limit': return 'límite'
            case 'Failed to load': return 'Error al cargar'
            case 'Prop': return 'Acccesorio'
            case 'Move to joint?': return '¿Pasar a la articulación?'
            case 'is now attached to': return 'ahora está adjunto a'
            case 'Select a joint to connect': return 'Selecciona una articulación para conectar'
            case 'has been disconnected from': return 'se ha desconectado de'
            case 'Failed to load': return 'Error al cargar'
            case 'There are too many users using this license key': return 'Hay demasiados usuarios usando esta clave de licencia'
            case 'Delete?': return '¿Eliminar?'
            case 'Are you sure you want to clear the scene?': return '¿Seguro que deseas borrar la escena?'
            case 'Exit hand editor': return "Cerrar editor de manos"
            case 'copied to clipboard': return 'copiado al portapapeles'

            default: lookup
        }
    }

    if (localStorage.getItem('lang') == 'ja') {
        switch (lookup) {
            // Tooltips
            case 'Add characters to scene': return 'シーンにキャラクターを追加する'
            case 'Shapes and props': return 'シェイプと小道具'
            case 'Upload image/model': return '画像/モデルのアップロード'
            case 'Undo action (Ctrl+Z)': return '操作を元に戻す (Ctrl+Z)'
            case 'Redo action (Ctrl+Shift+Z)': return '操作をやり直す (Ctrl+Shift+Z)'
            case 'Place on ground (J)': return '地面に置く (J)'
            case 'Toggle lock camera (L)': return 'カメラロックの切り替え (L)'
            case 'Toggle mirror posing mode (J)': return 'ミラーポーズの切り替え (J)'
            case 'Options': return 'オプション'
            case 'Tutorial': return 'チュートリアル'
            case 'Manipulate joints (Q)': return 'ジョイントを操作する (Q)'
            case 'Move (W)': return '移動 (W)'
            case 'Scale (E)': return '拡大縮小 (E)'
            case 'Hide tools (R)': return 'ツールを隠す (R)'
            case 'Poses': return 'ポーズ'
            case 'Hand poses': return '手のポーズ'
            case 'Scenes': return 'シーン'
            case 'Screenshot or export': return 'スクリーンショットまたはエクスポート'
            case 'Clear scene': return 'シーンを消去'

            // Tab tooltips
            case 'Select': return '選択'
            case 'Duplicate': return '複製'
            case 'Flip': return 'フリップ'
            case 'Hide toggle': return 'トグルを隠す'
            case 'Lock toggle': return 'トグルをロック'
            case 'Delete': return '削除'
            case 'Attach to character': return 'キャラクターへのアタッチメント'
            case 'Put on ground': return '地面に置く'

            // Tutorial
            case 'Want to use JustSketchMe like a pro?': return 'JustSketchMeをプロ並みに使いたい？'
            case 'Check out our': return 'この'
            case 'tutorial videos': return 'チュートリアルビデオをチェック'
            case 'Gotcha!': return 'わかった！'
            case 'Tap the': return ''
            case 'button in the top right to re-open this info.': return 'ボタンをタップするとこの情報がまた表示されます。'
            case "Don't show this again": return '今後は表示しない'
            
            case 'Add characters': return 'キャラクターを追加'
            case 'Set color, duplicate, flip, delete & hide joints': return 'カラー設定、複製、反転、ジョイントの削除と非表示'
            case 'Rotate joints': return 'ジョイントの回転'
            case 'Move objects': return 'オブジェクトの移動'
            case 'Scale objects': return 'オブジェクトの拡大縮小'
            case 'Hide all tools': return 'ツールを隠す'
            case 'Save camera position': return 'カメラの位置を保存する'
            case 'Save (hold) or restore (tap) camera position': return 'カメラの位置を保存する（長押し）または復元する（タップ）'
            case 'Lock camera': return 'ミラーポーズモード'
            case 'Mirror posing mode': return 'カメラをロック'
            case 'Settings': return '設定'
            case 'Hide tips': return '指示を隠す'
            case 'Take screenshot': return 'スクリーンショットを撮ります'
            
            // Character menu
            case 'Characters': return 'キャラクター'
            case 'Base': return '基本'
            case 'Stylised': return 'スタイライズド'
            case 'Realistic': return 'リアル'
            case 'Fantasy': return 'ファンタジー'
            case 'Other': return 'その他'
            case 'Male': return "男"
            case 'Female': return "女"
            case 'Teen female': return "ティーン 女"
            case 'Teen male': return "ティーン 男"
            case 'Tween female': return 'トゥイーン 女'
            case 'Tween male': return 'トゥイーン 男'
            case 'Child (older)': return 'こども (大きい)'
            case 'Child (younger)': return 'こども (小さい)'
            case 'Baby': return '赤ちゃん'
            case 'Anime female v1': return 'アニメ 女 v1'
            case 'Anime male v1': return 'アニメ 男 v1'
            case 'Anime female v2': return 'アニメ 女 v2'
            case 'Anime male v2': return 'アニメ 男 v2'
            case 'Stylized female': return 'スタイライズド 女'
            case 'Stylized male': return 'スタイライズド 男'
            case 'Chibi male': return 'チビ 男'
            case 'Chibi female': return 'チビ 女'
            case 'Superhero male': return 'スーパーヒーロー 男'
            case 'Superhero female': return 'スーパーヒーロー 女'
            case 'Chubby male': return 'ポッチャリ 男'
            case 'Chubby female': return 'ポッチャリ 女'
            case 'Skinny male': return 'やせ型 男'
            case 'Skinny female': return 'やせ型 女'
            case 'Muscular male': return '筋肉質 男'
            case 'Muscular female': return '筋肉質 女'
            case 'Mermaid': return '人魚 女'
            case 'Merman': return '人魚 男'
            case 'Skeleton': return 'スケルトン'
            case 'Dragon': return 'ドラゴン'
            case 'Lycan male': return 'オオカミ人間 男'
            case 'Lycan female': return 'オオカミ人間 女'
            case 'Art mannequin': return 'アートマネキン'
            case 'Dog': return '犬'
            case 'Cat': return '猫'
            case 'Female v1': return '女 v1'
            case 'Male v1': return '男 v1'
            case 'Large male': return '特大 男 (ベータ)'
            case 'Large female': return '特大 女 (ベータ)'

            // Prop menu
            case 'Shapes and props': return 'シェイプと小道具'
            case 'Basic shapes': return '基本図形'
            case 'Box': return '箱'
            case 'Cylinder': return '円柱'
            case 'Sphere': return '球体'
            case 'Cone': return '円錐'
            case 'Torus': return '輪'

            // Upload menu
            case 'Upload objects': return 'オブジェクトのアップロード'
            case 'Upload image': return '画像のアップロード'
            case 'Upload prop (.obj)': return '小道具 (.obj) をアップロードする'
            case 'Uploading may have issues with broken 3D models. If it gets funky reload the page.': return 'アップロードの際、3Dモデルが破損する問題が発生する場合があります。その場合はページを再読み込みしてください。'

            // Upgrade menu
            case 'Upgrade': return 'アップグレード'
            case 'Take your art to the next level!': return 'あなたのアートを次のレベルへ！'
            case 'Get JustSketchMe Pro!': return 'JustSketchMe Proをゲットしよう!'
            case 'Already have a license key?': return 'すでにライセンスキーを持ってる？'
            case 'License key...': return 'ライセンスキー...'
            case 'License key': return 'ライセンスキー'
            case 'Activate': return 'アクティベート'
            case 'https://justsketchme.lemonsqueezy.com/checkout/buy/24ec4be2-997a-4ed0-96e7-551c14dc1b78': return 'https://justsketchme.lemonsqueezy.com/checkout/buy/6657d262-1aee-4f39-94cb-d0ace6c36242'

            case 'Unlock all': return 'すべてのロックを解除ー'
            case '30+ models': return '30以上のモデル'
            case '200+ pre-set poses': return '200以上の設定済みのポーズ'
            case 'props': return '小道具'
            case 'saving': return 'セーブ'
            case 'and more!': return 'などなど！'

            // Options menu
            case 'Options': return 'オプション'
            case 'Scene': return 'シーン'
            case 'Apps': return 'アプリ'
            case 'Account': return 'アカウント'
            case '🌚 Dark mode': return '🌚 ダークモード'
            case '🔆 Ambient lighting': return '🔆 アンビエント照明'
            case '💡 Spot lighting': return '💡 スポット照明'
            case '👤 Hard shadows': return '👤 ハードシャドウ'
            case '🖊 Outline effect': return '🖊 アウトライン効果'
            case '🌐 Floor grid': return '🌐 フロアグリッド'
            case '🎥 Field of view': return '🎥 視界'
            case 'Reset light': return 'ライトのリセット'
            case '🔄 Reset app settings': return '🔄 アプリの設定をリセット'
            case '🤖 Update app': return '🤖 アプリのアップデート'
            case '🪵 Log out': return '🪵 ログアウト'
            case '🖥 Activate on other devices with:': return '🖥 他のデバイスでアクティベート:'
            case 'Up to 3 devices': return '最大3つのデバイス'
            case '🐞 Report a bug': return '🐞 バグを報告'
            case '🧜‍♂️ Suggest a feature': return '🧜‍♂️ 機能を提案'
            case '📜 Documentation': return '📜 ドキュメンテーション'
            case '❓ FAQ': return '❓ FAQ'
            case '💳 Manage subscription': return '💳 サブスクリプションの管理'

            // Pose menu
            case 'Poses': return 'ポーズ'
            case 'Saved poses': return '保存したポーズ'
            case 'Pose library': return 'ポーズライブラリー'
            case 'Download pose': return 'ポーズのダウンロード'
            case 'Save pose': return 'ポーズを保存'
            case 'Open pose': return 'ポーズを開く'
            case 'Neutral': return 'ニュートラル'
            case 'T pose': return 'Tポーズ'
            case 'Save': return '保存'
            case 'Category (optional)': return 'カテゴリー (オプション)'
            case 'Pose name': return 'ポーズ名'

            // Hand pose menu
            case 'Hand pose library': return 'ハンドポーズライブラリー'
            case 'Left': return '左'
            case 'Right': return '右'

            // Scene menu
            case 'Scenes': return 'シーン'
            case 'Save scene': return 'シーンの保存'
            case 'Download scene': return 'シーンのダウンロード'
            case 'Import scene': return 'シーンのインポート'
            case 'Name': return '名前'
            case 'Scene name': return 'シーン名'

            // Export menu
            case '🖼️ Take screenshot': return '🖼️ スクリーンショットを撮る'
            case 'Export model as .dae': return 'モデルを.dae形式でエクスポート'
            case 'Export model as .obj': return 'モデルを.obj形式でエクスポート'

            // Messages
            case 'Internet connection required to validate key': return 'キー認証にインターネット接続が必要です'
            case 'Checking license key...': return 'ライセンスキーを確認中...'
            case 'This license key has expired': return 'このライセンスキーは有効期限が切れています'
            case 'This license key does not exist or has expired': return 'このライセンスキーは存在しないか有効期限が切れています'
            case 'No character to apply pose to': return 'ポーズを適用するキャラクターがいません'
            case '<b>💃 Welcome to JustSketchMe Pro! 🕺</b> <br> All of the features and models have been unlocked!': return '<b>💃 JustSketchMe Proへようこそ! 🕺</b> <br>すべての機能とモデルがアンロックされました！'
            case 'Internet connection required for cloud features': return 'クラウド機能にはインターネット接続が必要です'
            case 'updated': return 'アップデートされた'
            case 'saved': return '保存された'
            case 'Overwrite': return '上書き保存'
            case 'Overwrite with': return '上書き保存'
            case 'File over the': return ''
            case 'limit': return 'を超えたファイル上限'
            case 'Failed to load': return '読み込みに失敗しました'
            case 'Move to joint?': return 'ジョイントに移動しますか？'
            case 'Prop': return '小道具'
            case 'is now attached to': return '接続済み'
            case 'Select a joint to connect': return '接続するジョイントを選択する'
            case 'has been disconnected from': return '切断された'
            case 'Failed to load': return '読み込みに失敗しました'
            case 'There are too many users using this license key': return 'このライセンスキーを使用しているユーザーが多すぎます'
            case 'Delete?': return '削除しますか？'
            case 'Are you sure you want to clear the scene?': return '本当にシーンを消去しますか？'
            case 'Exit hand editor': return "ハンドエディターを終了"
            case 'copied to the clipboard': return '- クリップボードにコピーされました'

            default: lookup
        }
    }

    return lookup
}