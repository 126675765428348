export default [
    {
        "title": "Axe",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/axe.obj"
    },
    {
        "title": "Bat",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/bat.obj"
    },
    {
        "title": "Battle axe",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/battle-axe.obj"
    },
    {
        "title": "Kunai dagger",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/kunai-dagger.obj"
    },
    {
        "title": "Shield",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/shield.obj"
    },
    {
        "title": "Spear",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/spear.obj"
    },
    {
        "title": "Trident",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/trident.obj"
    },
    {
        "title": "Excalibur",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/excalibur.obj"
    },
    {
        "title": "Karambit",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/karambit.obj"
    },
    {
        "title": "Katana",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/katana.obj"
    },
    {
        "title": "Kitchen knife",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/kitchen-knife.obj"
    },
    {
        "title": "Spartan sword",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/spartan-sword.obj"
    },
    {
        "title": "Sting sword",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/sting-sword.obj"
    },
    {
        "title": "Crossbow",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/crossbow.obj"
    },
    {
        "title": "Elven bow",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/elven-bow.obj"
    },
    {
        "title": "Orc bow",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/orc-bow.obj"
    },
    {
        "title": "Ranger bow",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/ranger-bow.obj"
    },
    {
        "title": "Handgun ",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/handgun.obj"
    },
    {
        "title": "Revolver",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/revolver.obj"
    },
    {
        "title": "Submachine gun",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/submachine-gun.obj"
    },
    {
        "title": "Rifle",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/rifle.obj"
    },
    {
        "title": "Bullet",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/bullet.obj"
    },
    {
        "title": "Double barrel",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/double-barrel.obj"
    },
    {
        "title": "Rocket launcher",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/rocket-launcher.obj"
    },
    {
        "title": "Sai",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/sai.obj"
    },
    {
        "title": "Pitchfork",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/pitchfork.obj"
    },
    {
        "title": "Cleaver",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/cleaver.obj"
    },
    {
        "title": "Cauldron",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/cauldron.obj"
    },
    {
        "title": "Candlestick",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/candlestick.obj"
    },
    {
        "title": "Coffin",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/coffin.obj"
    },
    {
        "title": "Jack O' Lantern",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/jack-o-lantern.obj"
    },
    {
        "title": "Succubus wings",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/succubus-wings.obj"
    },
    {
        "title": "Cat mask",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/cat-mask.obj"
    },
    {
        "title": "Ski mask",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/ski-mask.obj"
    },
    {
        "title": "Dragon wings",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/dragon-wings.obj"
    },
    {
        "title": "Fox Ears",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/fox-ears.obj"
    },
    {
        "title": "Love seat",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/love-seat.obj"
    },
    {
        "title": "Couch",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/couch.obj"
    },
    {
        "title": "Antique chair",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/antique-chair.obj"
    },
    {
        "title": "Bed",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/bed.obj"
    },
    {
        "title": "Book",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/book.obj"
    },
    {
        "title": "Witch's broom",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/witchs-broom.obj"
    },
    {
        "title": "Witch's hat",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/halloween/witchs-hat-1.obj"
    },
    {
        "title": "Old television",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/old-television.obj"
    },
    {
        "title": "Futuristic chair",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/futuristic-chair.obj"
    },
    {
        "title": "Old lamp post",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/old-lamp-post.obj"
    },
    {
        "title": "Lamp",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/lamp.obj"
    },
    {
        "title": "Stool",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/stool.obj"
    },
    {
        "title": "Table with vase",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/table-with-vase.obj"
    },
    {
        "title": "Table",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/table.obj"
    },
    {
        "title": "Basin",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/basin.obj"
    },
    {
        "title": "Toilet",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/toilet.obj"
    },
    {
        "title": "Medieval ceiling lamp",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/medieval-ceiling-lamp.obj"
    },
    {
        "title": "Chandelier",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/chandelier.obj"
    },
    {
        "title": "Bath",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/bath.obj"
    },
    {
        "title": "TV stand",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/tv-stand.obj"
    },
    {
        "title": "Fan",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/fan.obj"
    },
    {
        "title": "Silencer Pistol",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/silencer-pistol.obj"
    },
    {
        "title": "Talon blaster",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/talon-blaster.obj"
    },
    {
        "title": "Retro blaster",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/retro-blaster.obj"
    },
    {
        "title": "Mech wings",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/mech-wings.obj"
    },
    {
        "title": "Enforcer blade",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/enforcer-blade.obj"
    },
    {
        "title": "Cyber katana",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/cyber-katana.obj"
    },
    {
        "title": "Turret",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/turret.obj"
    },
    {
        "title": "Psi sniper",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/psi-sniper.obj"
    },
    {
        "title": "Gun blade",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/gun-blade.obj"
    },
    {
        "title": "Laser rifle",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/laser-rifle.obj"
    },
    {
        "title": "Ruiner",
        "category": "Futuristic",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/futuristic/ruiner.obj"
    },
    {
        "title": "Torii Gate",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/torii-gate.obj"
    },
    {
        "title": "Pillar",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/pillar.obj"
    },
    {
        "title": "Fountain",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/fountain.obj"
    },
    {
        "title": "Water well",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/water-well.obj"
    },
    {
        "title": "Town houses",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/town-houses.obj"
    },
    {
        "title": "Bridge",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/bridge.obj"
    },
    {
        "title": "Manor",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/manor.obj"
    },
    {
        "title": "Temple wall",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/temple-wall.obj"
    },
    {
        "title": "Fenced wall",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/fenced-wall.obj"
    },
    {
        "title": "Angel wings",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/angel-wings.obj"
    },
    {
        "title": "Three face mask",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/three-face-mask.obj"
    },
    {
        "title": "VR goggles",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/vr-goggles.obj"
    },
    {
        "title": "Potted plant",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/nature/potted-plant.obj"
    },
    {
        "title": "Orchid",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/nature/orchid.obj"
    },
    {
        "title": "Wispy tree",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/nature/wispy-tree.obj"
    },
    {
        "title": "House plant",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/nature/house-plant.obj"
    },
    {
        "title": "Potted Penny Gum",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/nature/potted-penny-gum.obj"
    },
    {
        "title": "Tree",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/nature/tree.obj"
    },
    {
        "title": "Rose",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/nature/rose.obj"
    },
    {
        "title": "Tulip",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/nature/tulip.obj"
    },
    {
        "title": "Magical staff",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/magical-staff.obj"
    },
    {
        "title": "Druid crown",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/druid-crown.obj"
    },
    {
        "title": "Antlers",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/antlers.obj"
    },
    {
        "title": "Fairy wings",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/fairy-wings.obj"
    },
    {
        "title": "Guandao",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/guandao.obj"
    },
    {
        "title": "Sunglasses",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/sunglasses.obj"
    },
    {
        "title": "Fancy sunglasses",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/fancy-sunglasses.obj"
    },
    {
        "title": "Crown",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/crown.obj"
    },
    {
        "title": "Dragon Mask",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/dragon-mask.obj"
    },
    {
        "title": "Prop cannon",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/prop-cannon.obj"
    },
    {
        "title": "Bear",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/bear.obj"
    },
    {
        "title": "Bull",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/bull.obj"
    },
    {
        "title": "Crocodile",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/crocodile.obj"
    },
    {
        "title": "Deer",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/deer.obj"
    },
    {
        "title": "Elephant",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/elephant.obj"
    },
    {
        "title": "Flamingo",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/flamingo.obj"
    },
    {
        "title": "Eagle",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/eagle.obj"
    },
    {
        "title": "Rabbit",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/rabbit.obj"
    },
    {
        "title": "T-Rex",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/t-rex.obj"
    },
    {
        "title": "Tiger",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/tiger.obj"
    },
    {
        "title": "Standing bear",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/standing-bear.obj"
    },
    {
        "title": "Army of horses",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/army-of-horses.obj"
    },
    {
        "title": "Crab",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/crab.obj"
    },
    {
        "title": "Dog",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/dog.obj"
    },
    {
        "title": "Dolphin",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/dolphin.obj"
    },
    {
        "title": "Fish 1",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/fish-1.obj"
    },
    {
        "title": "Fish 2",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/fish-2.obj"
    },
    {
        "title": "Fish 3",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/fish-3.obj"
    },
    {
        "title": "Giraffe",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/giraffe.obj"
    },
    {
        "title": "Great white",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/great-white.obj"
    },
    {
        "title": "Standing horse",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/standing-horse.obj"
    },
    {
        "title": "Trotting horse",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/trotting-horse.obj"
    },
    {
        "title": "Lobster",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/lobster.obj"
    },
    {
        "title": "Orca",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/orca.obj"
    },
    {
        "title": "Pig",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/pig.obj"
    },
    {
        "title": "Conch",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/conch.obj"
    },
    {
        "title": "Walking tiger",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/walking-tiger.obj"
    },
    {
        "title": "Rocks",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/rocks.obj"
    },
    {
        "title": "Fallen log",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/nature/fallen-log.obj"
    },
    {
        "title": "Duck",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/duck.obj"
    },
    {
        "title": "Heron",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/heron.obj"
    },
    {
        "title": "Perched falcon",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/perched-falcon.obj"
    },
    {
        "title": "Rooster",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/rooster.obj"
    },
    {
        "title": "Ostrich",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/ostrich.obj"
    },
    {
        "title": "Flock of birds",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/flock-of-birds.obj"
    },
    {
        "title": "Little bird",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/little-bird.obj"
    },
    {
        "title": "Lion Statue",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/lion-statue.obj"
    },
    {
        "title": "Roman statue",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/roman-statue.obj"
    },
    {
        "title": "Fancy couch",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/decor/fancy-couch.obj"
    },
    {
        "title": "Circle of pillars",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/circle-of-pillars.obj"
    },
    {
        "title": "Roach",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/roach.obj"
    },
    {
        "title": "Scorpion",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/scorpion.obj"
    },
    {
        "title": "Chameleon",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/chameleon.obj"
    },
    {
        "title": "Praying Mantis",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/praying-mantis.obj"
    },
    {
        "title": "Spider",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/animals/spider.obj"
    },
    {
        "title": "Medusa Statue",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/medusa-statue.obj"
    },
    {
        "title": "Cane",
        "category": "Mobility aids",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mobility-aids/cane.obj"
    },
    {
        "title": "Wheelchair ",
        "category": "Mobility aids",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mobility-aids/wheelchair.obj"
    },
    {
        "title": "Cursed staff",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/magical/cursed-staff.obj"
    },
    {
        "title": "Arboreal Sword",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/faebruary/arboreal-sword.obj"
    },
    {
        "title": "Crystal Ball",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/faebruary/crystal-ball.obj"
    },
    {
        "title": "Sylvan Wand",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/faebruary/sylvan-wand.obj"
    },
    {
        "title": "Fae Staff",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/faebruary/fae-staff.obj"
    },
    {
        "title": "Floating Crystals",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/faebruary/floating-crystals.obj"
    },
    {
        "title": "Magical Girl Sword",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/faebruary/magical-girl-sword.obj"
    },
    {
        "title": "Magical Tree",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/faebruary/magical-tree.obj"
    },
    {
        "title": "Crystal Cluster",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/faebruary/crystal-cluster.obj"
    },
    {
        "title": "Crystal Cluster 2",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/faebruary/crystal-cluster-2.obj"
    },
    {
        "title": "Stone Golem",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/magical/stone-golem.obj"
    },
    {
        "title": "Druid mask",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/magical/druid-mask.obj"
    },
    {
        "title": "Runic sword",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/magical/runic-sword.obj"
    },
    {
        "title": "Alchemic Distillery",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/magical/alchemic-distillery.obj"
    },
    {
        "title": "Potion",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/magical/potion.obj"
    },
    {
        "title": "Mystic tome",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/magical/mystic-tome.obj"
    },
    {
        "title": "Sorcerer's chamber",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/magical/sorcerers-chamber.obj"
    },
    {
        "title": "Thorns",
        "category": "Magical",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/magical/thorns.obj"
    },
    {
        "title": "Bicycle",
        "category": "Vehicles",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/vehicles/bicycle.obj"
    },
    {
        "title": "Drone scout",
        "category": "Vehicles",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/vehicles/drone-scout.obj"
    },
    {
        "title": "Cyber Speeder",
        "category": "Vehicles",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/vehicles/cyber-speeder.obj"
    },
    {
        "title": "Small boat",
        "category": "Vehicles",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/vehicles/small-boat.obj"
    },
    {
        "title": "Vintage convertible",
        "category": "Vehicles",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/vehicles/vintage-convertible.obj"
    },
    {
        "title": "Classic motorbike",
        "category": "Vehicles",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/vehicles/classic-motorbike.obj"
    },
    {
        "title": "Spaceship",
        "category": "Vehicles",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/vehicles/spaceship.obj"
    },
    {
        "title": "Tank",
        "category": "Vehicles",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/vehicles/tank.obj"
    },
    {
        "title": "Stone arch",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/stone-arch.obj"
    },
    {
        "title": "Large rock",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/large-rock.obj"
    },
    {
        "title": "Stalagmites",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/stalagmites.obj"
    },
    {
        "title": "Shrine lamp",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/shrine-lamp.obj"
    },
    {
        "title": "Ancient gateway",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/environment/ancient-gateway.obj"
    },
    {
        "title": "Arrow",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/ranged-weapons/arrow.obj"
    },
    {
        "title": "Quiver",
        "category": "Ranged weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/quiver.obj"
    },
    {
        "title": "Shuriken",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/shuriken.obj"
    },
    {
        "title": "Sakura katana",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/sakura-katana.obj"
    },
    {
        "title": "Kukri knife",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/kukri-knife.obj"
    },
    {
        "title": "Rogue's dagger",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/rogues-dagger.obj"
    },
    {
        "title": "Nunchaku",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/nunchaku.obj"
    },
    {
        "title": "Walker",
        "category": "Mobility aids",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mobility-aids/walker.obj"
    },
    {
        "title": "Pigtails",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/pig-tails.obj"
    },
    {
        "title": "Siamese fighter",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mermay-2022/siamese-fighter.obj"
    },
    {
        "title": "Jellyfish",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mermay-2022/jellyfish.obj"
    },
    {
        "title": "Seahorse",
        "category": "Animals",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mermay-2022/seahorse.obj"
    },
    {
        "title": "Mermaid bra",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mermay-2022/mermaid-bra.obj"
    },
    {
        "title": "Treasure chest",
        "category": "Decor",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mermay-2022/treasure-chest.obj"
    },
    {
        "title": "Lighthouse",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mermay-2022/lighthouse.obj"
    },
    {
        "title": "Pearl clam",
        "category": "Environment",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/mermay-2022/pearl-clam.obj"
    },
    {
        "title": "Spear of Longinus",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/spear-of-longinus.obj"
    },
    {
        "title": "Amazonian spear",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/amazonian-spear.obj"
    },
    {
        "title": "Ancient shield",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/ancient-shield.obj"
    },
    {
        "title": "Afro puffs",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/afro-puffs.obj"
    },
    {
        "title": "Anime boy",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/anime-boy.obj"
    },
    {
        "title": "Bowl cut",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/bowl-cut.obj"
    },
    {
        "title": "Groomed man ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/groomed-man.obj"
    },
    {
        "title": "Hound dog ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/hound-dog.obj"
    },
    {
        "title": "Karen",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/karen.obj"
    },
    {
        "title": "Meek ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/meek.obj"
    },
    {
        "title": "Long with headband ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/long-with-headband.obj"
    },
    {
        "title": "Long and loose",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/long-and-loose.obj"
    },
    {
        "title": "Low ponytail",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/low-ponytail.obj"
    },
    {
        "title": "Mermaid hair ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/mermaid-hair.obj"
    },
    {
        "title": "Neat and suave ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/neat-and-suave.obj"
    },
    {
        "title": "Pinned back ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/pinned-back.obj"
    },
    {
        "title": "High ponytail",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/high-ponytail.obj"
    },
    {
        "title": "RPG rebel",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/rpg-rebel.obj"
    },
    {
        "title": "Shogun",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/shogun.obj"
    },
    {
        "title": "Sleek bob ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/sleek-bob.obj"
    },
    {
        "title": "Slick rick ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/slick-rick.obj"
    },
    {
        "title": "Curly and loose",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/curly-and-loose.obj"
    },
    {
        "title": "Wavy bob",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/wavy-bob.obj"
    },
    {
        "title": "90's rave glasses",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/90s-rave-glasses.obj"
    },
    {
        "title": "Astronaut helmet",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/astronaut-helmet.obj"
    },
    {
        "title": "Corporate glasses",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/corporate-glasses.obj"
    },
    {
        "title": "Gaspunk",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/gaspunk.obj"
    },
    {
        "title": "Hipster glasses",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/hipster-glasses.obj"
    },
    {
        "title": "Nurse hat",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/nurse-hat.obj"
    },
    {
        "title": "Cat's eye glasses",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/cats-eye-glasses.obj"
    },
    {
        "title": "Shinobi mask",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/shinobi-mask.obj"
    },
    {
        "title": "Simple cap",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/simple-cap.obj"
    },
    {
        "title": "Wandering samurai hat",
        "category": "Accessories",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/accessories/wandering-samurai-hat.obj"
    },
    {
        "title": "Android 18",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/android-18.obj"
    },
    {
        "title": "Casual with glasses",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/casual-with-glasses.obj"
    },
    {
        "title": "Courtesan updo ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/courtesan-updo.obj"
    },
    {
        "title": "Edgy with brows",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/edgy-with-brows.obj"
    },
    {
        "title": "Geisha",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/geisha.obj"
    },
    {
        "title": "Kuudere",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/kuudere.obj"
    },
    {
        "title": "Laid back pigtails",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/laid-back-pigtails.obj"
    },
    {
        "title": "Low plait",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/low-plait.obj"
    },
    {
        "title": "Mecha pilot",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/mecha-pilot.obj"
    },
    {
        "title": "Moe",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/moe.obj"
    },
    {
        "title": "Odango",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/odango.obj"
    },
    {
        "title": "Rusty ",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/rusty.obj"
    },
    {
        "title": "PokéMom",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/pokemom.obj"
    },
    {
        "title": "Tsundere",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/tsundere.obj"
    },
    {
        "title": "Wise sage",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/wise-sage.obj"
    },
    {
        "title": "Middle part with glasses",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/middle-part-with-glasses.obj"
    },
    {
        "title": "Medusa",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/medusa.obj"
    },
    {
        "title": "Novice",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/novice.obj"
    },
    {
        "title": "Reluctant hero",
        "category": "Hair",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/hair/reluctant-hero.obj"
    },
    {
        "title": "Syringe",
        "category": "Melee Weapons",
        "url": "https://justsketchme-models.sfo2.cdn.digitaloceanspaces.com/1111-JSM-MODELS/melee-weapons/syringe.obj"
    }
]