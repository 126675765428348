require("babel-core/register");
require("babel-polyfill");

import * as THREE from "three";
import { OrbitControls } from "./OverwriteThreeJS/OrbitControls";
import { OrbitControlsGizmo } from "./OverwriteThreeJS/OrbitControlsGizmo";
import { OutlineEffect } from "three/examples/jsm/effects/OutlineEffect.js";

import { setIsActive, setIsInactive } from "./index";
import { t } from "./helpers/Translate";
import Light from "./sceneSubjects/Light";
import Control from "./sceneSubjects/Control";
import Character from "./sceneSubjects/Character";
import Prop from "./sceneSubjects/Prop";
import { buildMenus } from "./helpers/Menus";
import { clearCharacterTabs } from "./helpers/Tabs";
import SelectedManager from "./helpers/SelectedManager";
import StateManager from "./helpers/StateManager";
import { buildRenderer, buildCamera, animateTransition } from "./helpers/Helpers";
import { showMessage } from "./Message";

import characters from "./constants/characters";

export let camera = null;
export let scene = null;
export let orbitControl = null;
export let cameraLocked = false;
export let cameraPosition = {};
export let mirrorMode = false;

export let options = {
  darkMode: localStorage.getItem("darkMode") == "true" || false,
  ambientLight: 6,
  directionalLight: 3,
  hardShadows: localStorage.getItem("hardShadows") == "true" || false,
  outlineEffect: localStorage.getItem("outlineEffect") != "false",
  showFloor: true,
  fov: 45,
  ratio: 0,
  jointScaling: localStorage.getItem("jointScaling") == "true" || false
}

class SceneManager {
  constructor(canvas) {
    this.canvas = canvas;
    scene = new THREE.Scene();

    this.activeGizmo = "rotate";

    this.stateManager = new StateManager();
    this.selectedManager = new SelectedManager();

    this.gridHelper = new THREE.PolarGridHelper(300, 10);
    scene.add(this.gridHelper);

    this.screenDimensions = {
      width: canvas.width,
      height: canvas.height
    };
    this.renderer = buildRenderer(this.screenDimensions);
    this.effect = new OutlineEffect(this.renderer);
    camera = buildCamera(this.screenDimensions);
    scene.add(camera);
    this.cameraLight = new THREE.DirectionalLight(0xffffff, 0.6);
    camera.add(this.cameraLight);

    orbitControl = new OrbitControls(camera, this.renderer.domElement);
    orbitControl.target.set(0, 100, 0);
    orbitControl.screenSpacePanning = true;
    orbitControl.zoomSpeed = 0.5;
    orbitControl.update();

    // Obit Controls Gizmo
    const controlsGizmo = new OrbitControlsGizmo(orbitControl, {
      size: 100,
      padding: 8
    });

    // Add the Gizmo to the document
    document.body.appendChild(controlsGizmo.domElement);

    this.sceneCharacters = [];
    this.sceneProps = [];

    this.ambientLight = new Light(
      new THREE.AmbientLight(0x4a4a4a, options.ambientLight),
      new THREE.Vector3(0, 0, 0)
    );
    this.directionalLight = new Light(
      new THREE.DirectionalLight(0xffffff, options.directionalLight),
      new THREE.Vector3(100, 200, 100),
      true
    );
    this.directionalLight.toggleShadows(options.hardShadows);
    this.lights = [this.ambientLight, this.directionalLight];

    this.lastPoseName = "";
    this.lastSceneName = "";

    this.gizmo = new Control(null, "rotate");

    this.setDarkMode(options.darkMode);
    this.setHardShadows(options.hardShadows);
    this.setOutlineEffect(options.outlineEffect);

    buildMenus();
    this.createSceneSubjects();
  }

  loading() {
    let loading = false;
    this.sceneCharacters.forEach(subject => {
      if (subject.loading) {
        loading = true;
      }
    });
    return loading;
  }
  print() {
    console.log(scene.children);
  }

  setDarkMode(value) {
    options.darkMode = value;
    localStorage.setItem("darkMode", options.darkMode);

    if (value) {
      document.querySelector("body").classList.add("dark");
      scene.background = new THREE.Color(0x363636);
    } else {
      document.querySelector("body").classList.remove("dark");
      scene.background = null;
    }
  }

  setAmbientLightIntensity(intensity) {
    options.ambientLight = intensity;
    this.ambientLight.setIntensity(options.ambientLight);
  }

  setDirectionalLightIntensity(intensity) {
    options.directionalLight = intensity;
    this.directionalLight.setIntensity(options.directionalLight);
  }

  setHardShadows(value) {
    options.hardShadows = value;
    localStorage.setItem("hardShadows", options.hardShadows);
    this.directionalLight.toggleShadows(options.hardShadows);
  }

  setOutlineEffect(value) {
    options.outlineEffect = value;
    localStorage.setItem("outlineEffect", options.outlineEffect);
    this.effect.enabled = options.outlineEffect;
  }

  setFloorGrid(enabled) {
    options.showFloor = enabled;
    this.gridHelper.visible = options.showFloor;
  }

  setJointScaling(value) {
    options.jointScaling = value;
    localStorage.setItem("jointScaling", options.jointScaling);
    this.selectedManager.character.setJointsVisibility();
  }

  setFieldOfView(value) {
    options.fov = value;
    camera.fov = options.fov;
    camera.updateProjectionMatrix();
  }

  resetLight() {
    this.directionalLight.light.position.copy(new THREE.Vector3(100, 200, 100));
    this.setDirectionalLightIntensity(3);
    this.setAmbientLightIntensity(6);
    buildMenus();
  }

  setGizmo(gizmo) {
    this.activeGizmo = gizmo;

    this.gizmo.setMode(gizmo);

    for (let i = 0; i < this.sceneCharacters.length; i++) {
      if (!this.sceneCharacters[i].alive) {
        this.sceneCharacters.splice(i, 1);
      }
      if (this.sceneCharacters[i]) {
        this.sceneCharacters[i].setGizmo();
      }
    }

    const activeGizmoElement = document.querySelector(
      ".gizmo-button.is-active"
    );
    if (activeGizmoElement) {
      setIsInactive(activeGizmoElement);
    }
    setIsActive(document.querySelector(`#${gizmo}-gizmo`));
  }

  addPropToScene({
    type,
    position = null,
    scale = null,
    rotation = null,
    imageUrl = null,
    objectInfo = null,
    title = null,
    color = null,
    icon = null,
    attachedTo = null
  }) {
    new Prop({
      type,
      position,
      scale,
      rotation,
      imageUrl,
      objectInfo,
      title,
      color,
      icon,
      attachedTo
    });
  }

  addCharacterToScene({
    id,
    info,
    position = null,
    scale = null,
    pose = null,
    color = null,
    jointScales = null
  }) {
    const newCharacter = new Character({
      id,
      info,
      position,
      scale,
      pose,
      color,
      jointScales
    });
    this.sceneCharacters.push(newCharacter);
    return newCharacter;
  }

  createSceneSubjects() {
    if (localStorage.getItem("sceneState")) {
      try {
        this.loadScene(
          null,
          null,
          JSON.parse(localStorage.getItem("sceneState"))
        );
      } catch (error) {
        console.error(error);
        this.addCharacterToScene({ info: characters[0] });
      }
    } else {
      this.addCharacterToScene({ info: characters[0] });
    }

    this.saveCameraPosition(false);
  }

  clearScene() {
    if (confirm(t("Are you sure you want to clear the scene?"))) {
      clearInterval(this.stateManager.saveInterval);
      localStorage.removeItem("sceneState");
      localStorage.removeItem("user_data");
      window.location.reload();
    }
  }

  toggleEffect() {
    this.effect.enabled = !this.effect.enabled;
  }

  takeScreenshot() {
    const a = document.createElement("a");
    a.target = "_blank";
    a.download = true;
    scene.background = null;
    this.renderer.setClearColor(0x000000, 0);
    setTimeout(() => {
      a.href = this.renderer.domElement.toDataURL(
        "image/png",
        "image/octet-stream"
      );

      const screenshotCanvas = document.createElement("canvas");
      document.body.appendChild(screenshotCanvas);

      const screenshotImage = new Image();
      const captionImage = new Image();
      screenshotImage.src = this.renderer.domElement.toDataURL(
        "image/png",
        "image/octet-stream"
      );

      screenshotImage.onload = () => {
        screenshotCanvas.width = screenshotImage.width;
        screenshotCanvas.height = screenshotImage.height;

        const ctx = screenshotCanvas.getContext("2d");
        ctx.drawImage(
          screenshotImage,
          0,
          0,
          screenshotImage.width,
          screenshotImage.height
        );

        captionImage.src = "../images/screenshot-cap.png";
        captionImage.onload = () => {
          // const width = screenshotImage.width > 500 ? 500 : screenshotImage.width
          // const height = screenshotImage.width > 500 ? 160 : screenshotImage.width * 0.32
          ctx.drawImage(captionImage, 0, 0, 300, 96);
          a.href = screenshotCanvas.toDataURL("image/png");
          a.download = "JustSketchMe - Screenshot.png";
          a.click();
          a.remove();
          screenshotCanvas.remove();
          if (localStorage.getItem("darkMode") == "true") {
            this.setDarkMode(true);
          }
        };
      };
    }, 50);
  }

  saveCameraPosition(message=true) {
    cameraPosition = {
      position: {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
      },
      rotation: {
        x: camera.rotation.x,
        y: camera.rotation.y,
        z: camera.rotation.z
      },
      target: {
        x: orbitControl.target.x,
        y: orbitControl.target.y,
        z: orbitControl.target.z
      },
      fov: camera.fov
    };

    if (message) {
      showMessage("Camera position stored. Single tap this button to restore camera position.", 4000);
    }
  }

  setCameraPosition() {

    animateTransition(
      {
        rx: camera.rotation.x,
        ry: camera.rotation.y,
        rz: camera.rotation.z,
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
        tx: orbitControl.target.x,
        ty: orbitControl.target.y,
        tz: orbitControl.target.z
      },
      {
        rx: cameraPosition.rotation.x,
        ry: cameraPosition.rotation.y,
        rz: cameraPosition.rotation.z,
        x: cameraPosition.position.x,
        y: cameraPosition.position.y,
        z: cameraPosition.position.z,
        tx: cameraPosition.target.x,
        ty: cameraPosition.target.y,
        tz: cameraPosition.target.z
      },
      tween => {
        camera.rotation.set(tween.rx, tween.ry, tween.rz);
        camera.position.set(tween.x, tween.y, tween.z);
        orbitControl.target.set(tween.tx, tween.ty, tween.tz);
      }
    )

    camera.fov = cameraPosition.fov;
    camera.updateProjectionMatrix();
    orbitControl.update();
  }

  getSceneState(cloud = false) {
    const sceneProperties = {
      characters: [],
      props: [],
      light: {
        position: null,
        directionalIntensity: null,
        ambientIntensity: null
      },
      camera: {
        position: null,
        rotation: null,
        target: null,
        fov: null
      }
    };

    this.sceneCharacters.forEach(subject => {
      const characterProperties = {
        info: {
          id: subject.info.id,
          name: subject.info.name
        },
        id: subject.id,
        position: subject.getPosition(),
        scale: subject.getScale(),
        alive: subject.alive,
        color: subject.color,
        pose: subject.getPose(),
        jointScales: subject.getJointScales()
      };

      sceneProperties.characters.push(characterProperties);
    });

    this.sceneProps.forEach(prop => {
      const shapeProperties = {
        info: prop.info,
        id: prop.id,
        position: prop.getPosition(),
        scale: prop.getScale(),
        rotation: prop.getRotation(),
        objectInfo: !cloud ? prop.objectInfo : "", // Dont store object info in the cloud
        imageUrl: prop.imageUrl,
        title: prop.title,
        alive: prop.alive,
        color: prop.color,
        icon: prop.icon,
        attachedTo: prop.attachedTo
      };

      sceneProperties.props.push(shapeProperties);
    });

    sceneProperties.light.position = this.directionalLight.light.position;
    sceneProperties.light.directionalIntensity = this.directionalLight.light.intensity;
    sceneProperties.light.ambientIntensity = this.ambientLight.light.intensity;
    sceneProperties.camera.position = camera.position;
    sceneProperties.camera.rotation = camera.rotation;
    sceneProperties.camera.target = orbitControl.target;
    sceneProperties.camera.fov = camera.fov;

    return sceneProperties;
  }

  loadScene(sceneName, sceneCategory, loadedScene) {
    this.gizmo.detach();
    this.lastSceneName = sceneName;
    this.lastSceneCategory = sceneCategory;

    this.sceneCharacters.forEach(subject => subject.delete());
    this.sceneProps.forEach(subject => subject.delete());

    this.sceneCharacters = [];
    this.sceneProps = [];
    clearCharacterTabs();
    this.stateManager.reset();
    loadedScene.characters.forEach(loadedCharacter => {
      if (loadedCharacter.alive != false) {
        const character = characters.find(
          info => info.id === loadedCharacter.info.id
        );

        this.addCharacterToScene({
          id: loadedCharacter.id,
          info: character,
          position: loadedCharacter.position,
          scale: loadedCharacter.scale,
          pose: loadedCharacter.pose,
          color: loadedCharacter.color,
          jointScales: loadedCharacter.jointScales
        });
      }
    });
    loadedScene.props.forEach(prop => {
      if (prop.alive != false) {
        new Prop({
          id: prop.id,
          type: prop.info.id,
          position: prop.position,
          scale: prop.scale,
          rotation: prop.rotation,
          imageUrl: prop.imageUrl,
          objectInfo: prop.objectInfo,
          title: prop.title,
          color: prop.color,
          icon: prop.icon,
          attachedTo: prop.attachedTo
        });
      }
    });

    this.directionalLight.light.position.set(
      loadedScene.light.position.x,
      loadedScene.light.position.y,
      loadedScene.light.position.z
    );
    this.directionalLight.light.intensity =
      loadedScene.light.directionalIntensity;
    this.ambientLight.light.intensity = loadedScene.light.ambientIntensity;

    if (loadedScene.camera) {
      camera.position.set(
        loadedScene.camera.position.x,
        loadedScene.camera.position.y,
        loadedScene.camera.position.z
      );
      camera.rotation.set(
        loadedScene.camera.rotation.x,
        loadedScene.camera.rotation.y,
        loadedScene.camera.rotation.z
      );
      if (loadedScene.camera.target) {
        orbitControl.target.set(
          loadedScene.camera.target.x,
          loadedScene.camera.target.y,
          loadedScene.camera.target.z
        )
      }
      camera.fov = loadedScene.camera.fov;
      camera.updateProjectionMatrix();
      orbitControl.update();
    }
  }

  lockCamera(buttonElement) {
    cameraLocked = !cameraLocked;
    orbitControl.enabled = !cameraLocked;

    if (cameraLocked) {
      buttonElement
        .querySelector("svg use")
        .setAttribute("href", "images/feather-sprite.svg#lock");
      setIsActive(buttonElement);
    } else {
      buttonElement
        .querySelector("svg use")
        .setAttribute("href", "images/feather-sprite.svg#unlock");
      setIsInactive(buttonElement);
    }
  }

  toggleMirrorPosingMode(buttonElement) {
    mirrorMode = !mirrorMode;
    toggleIsActive(buttonElement)
  }

  update() {
    this.effect.render(scene, camera);
  }

  onWindowResize() {
    const { width, height } = canvas;

    this.screenDimensions.width = width;
    this.screenDimensions.height = height;

    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    this.effect.setSize(width, height);
  }
}

export default SceneManager;
