import CoolGUI from "../CoolGUI"
import * as THREE from "three"
import { sceneManager, license, store, api, toggleIsActive } from "../index"
import { options } from "../SceneManager"

import props from "../constants/props"
import { t } from "./Translate"
import characters from "../constants/characters"
import { connected } from "./Helpers"
import { showMessage } from "../Message"

import {
	openSavePosePopup,
	openSaveScenePopup,
	loadPose,
	loadHandLibraryPose,
	exportScene,
	exportPose,
	importScene,
	importPose,
} from "./SaveManager"
import propPacks from "../constants/propPacks"
import poseLibrary from "../constants/poseLibrary"

export const openMenu = (menuButton, menu) => {
	closeAllMenus(menu, menuButton)
	toggleIsActive(menu)
	toggleIsActive(menuButton)
}

export const closeAllMenus = (menu, menuButton) => {
	const menuElement =
		typeof menu == "object" ? menu : document.querySelector(menu)
	const menuButtonElement =
		typeof menuButton == "object"
			? menuButton
			: document.querySelector(menuButton)

	document.querySelectorAll(`.popup-menu`).forEach((item) => {
		if (item != menuElement) {
			setIsInactive(item)
		}
	})
	document.querySelectorAll(`.menu-button`).forEach((item) => {
		if (item != menuButtonElement) {
			setIsInactive(item)
		}
	})

	document.querySelectorAll(".popup").forEach((item) => {
		if (item != menuButtonElement) {
			setIsInactive(item)
		}
	})
}

const star = () => {
	if(!license.valid) {
		return "⭐ "
	}
	return ""
}

const buildCharacterMenu = () => {
	const characterMenu = new CoolGUI(t("Characters"), "#character-menu")

	// TODO: Get categories to automatically generate category menus
	const baseCharacters = characterMenu.addTab(t("Base"))
	const animeCharacters = characterMenu.addTab(t("Stylised"))
	const realisticCharacters = characterMenu.addTab(t("Realistic"))
	const fantasyCharacters = characterMenu.addTab(t("Fantasy"))
	const otherCharacters = characterMenu.addTab(t("Other"))

	characters
		.filter((character) => !character.hidden)
		.forEach((character) => {
			const thumbnailHTML = `<img src=${character.thumbnail} class="thumbnail"/>`
			const locked = !license.valid && character.locked ? "locked" : ""
			const name = `${character.thumbnail && thumbnailHTML} ${t(character.title)}`
			let loadCallback = () => {}
			if (locked) {
				loadCallback = () => license.check()
			} else {
				loadCallback = () => sceneManager.addCharacterToScene({info: character})
			}

			if (!character.category) {
				baseCharacters.addItem(name, loadCallback, null, locked)
			} else if (character.category == "Other") {
				otherCharacters.addItem(name, loadCallback, null, locked)
			} else if (character.category == "Fantasy") {
				fantasyCharacters.addItem(name, loadCallback, null, locked)
			} else if (character.category == "Stylised") {
				animeCharacters.addItem(name, loadCallback, null, locked)
			} else if (character.category == "Realistic") {
				realisticCharacters.addItem(name, loadCallback, null, locked)
			}
		})
	characterMenu.buildMenu()
}

export const buildPropsMenu = () => {
	const propsMenu = new CoolGUI(t("Shapes and props"), "#props-menu")

	const visibleProps = props.filter((prop) => prop.menuItem)
	visibleProps.forEach((prop) => {
		const thumbnailIcon = `<div class="thumbnail icon"><svg><use xlink:href="images/feather-sprite.svg#${prop.icon}" /></svg></div>`
		propsMenu.addAccordion(
			t("Basic shapes"),
			`${thumbnailIcon} ${t(prop.name)}`,
			() => {
				sceneManager.addPropToScene({type: prop.id})
				closeAllMenus()
			}
		)
	})

	let allProps = propPacks
	if (store.state.propPacks && store.state.propPacks.length > 100) {
		allProps = store.state.propPacks
	}

	if (license.valid) {
		allProps.forEach((prop) => {
			let thumbnailIcon = `<div class="thumbnail icon"><svg><use xlink:href="images/feather-sprite.svg#feather" /></svg></div>`
			if (prop.icon) {
				thumbnailIcon = `<img src="${prop.icon}" width="50"/>`
			}
			propsMenu.addAccordion(
				prop.category,
				`${thumbnailIcon} ${prop.title}`,
				() => {
					sceneManager.addPropToScene({
						type: "model",
						imageUrl: prop.url,
						title: prop.title,
						scale: prop.correct_size && new THREE.Vector3(1, 1, 1),
						icon: prop.icon
					})
					
					closeAllMenus()
				}
			)
		})
	} else {
		allProps.forEach((prop) => {
			propsMenu.addAccordion(
				prop.category,
				prop.title,
				license.check,
				null,
				"locked"
			)
		})
	}
	
	propsMenu.buildMenu()
}

const buildUploadMenu = () => {
	const uploadMenu = new CoolGUI("Upload objects", "#upload-menu")

	let thumbnailIcon = `<div class="thumbnail icon"><svg><use xlink:href="images/feather-sprite.svg#image" /></svg></div>`
	uploadMenu.addItem(
		`${star()}${thumbnailIcon} ${t('Upload image')}`,
		license.valid ? () => sceneManager.addPropToScene({type: 'image'}) : license.check
	)
	thumbnailIcon = `<div class="thumbnail icon"><svg><use xlink:href="images/feather-sprite.svg#feather" /></svg></div>`
	uploadMenu.addItem(
		`${star()}${thumbnailIcon} ${t('Upload prop (.obj)')}`,
		license.valid ? () => sceneManager.addPropToScene({type: 'model'}) : license.check
	)

	uploadMenu.addText(
		`<small>${t('Uploading may have issues with broken 3D models. If it gets funky reload the page.')}</small>`
	)
	uploadMenu.buildMenu()
}

const buildUpgradeMenu = () => {
	const upgradeMenu = new CoolGUI(t("Upgrade"), "#upgrade-menu")
	upgradeMenu.addText(
		`<span class="hl hot-pink">${t('Take your art to the next level!')}</span>`,
		"heading"
	)

	upgradeMenu.addText(
		`<p style="text-align:center;line-height:1.5;">
			<span>${t('Unlock all')}</span>
			<span class="hl tasty-teal">${t('30+ models')}</span>,
			<span class="hl fresh-mint">${t('200+ pre-set poses')}</span>,
			<span class="hl chilled-pink">${t('props')}</span>,
			<span class="hl blackish">${t('saving')}</span>
			<span>${t('and more!')}</span>
			<br>
			<img src="https://hermanmartinus.github.io/justsketchme-frontend/images/features/pose-library.png" style="max-width: 100%; max-height: unset;" />
			<br>
			<span id="ppp"></span>
		</p>`
	)

	let checkoutURL = 'https://justsketchme.lemonsqueezy.com/checkout/buy/24ec4be2-997a-4ed0-96e7-551c14dc1b78'
	upgradeMenu.addButton(
		t("Get JustSketchMe Pro!"),
		() => {
			window.open(t(checkoutURL))
		},
		"yellow"
	)

	if (!window.isWebView) {
		upgradeMenu.addTextInput(
			t("Already have a license key?"),
			t("License key..."),
			t("Activate"),
			(key) => license.validate(key)
		)
	}

	upgradeMenu.buildMenu()
}

const buildOptionsMenu = () => {
	const optionsMenu = new CoolGUI(t("Options"), "#options-menu")
	const sceneOptions = optionsMenu.addTab(t("Scene"))
	sceneOptions.addText(`
		<a href="?lang=en">🇬🇧</a>
		<a href="?lang=pt">🇵🇹</a>
		<a href="?lang=es">🇪🇸</a>
		<a href="?lang=ja">🇯🇵</a>
	`)

	if (license.valid) {
		sceneOptions.addSwitch(
			t("🦵 Adjust limb sizes (exp.)"),
			(value) => sceneManager.setJointScaling(value),
			options.jointScaling
		)
	} else {
		sceneOptions.addItem(
			t("🦵 Adjust limb sizes (exp.)"),
			() => alert("Experimental features are only available in JustSketchMe Pro.")
		)
	}
	sceneOptions.addSwitch(
		t("🌚 Dark mode"),
		(value) => sceneManager.setDarkMode(value),
		options.darkMode
	)
	sceneOptions.addSlider(
		t("🔆 Ambient lighting"),
		(value) => sceneManager.setAmbientLightIntensity(value),
		0,
		20,
		options.ambientLight,
		0.5
	)
	sceneOptions.addSlider(
		t("💡 Spot lighting"),
		(value) => sceneManager.setDirectionalLightIntensity(value),
		0,
		10,
		options.directionalLight,
		0.5
	)
	sceneOptions.addSwitch(
		t("👤 Hard shadows"),
		(value) => sceneManager.setHardShadows(value),
		options.hardShadows
	)
	sceneOptions.addSwitch(
		t("🖊 Outline effect"),
		(value) => sceneManager.setOutlineEffect(value),
		options.outlineEffect
	)
	sceneOptions.addSwitch(
		t("🌐 Floor grid"),
		(value) => sceneManager.setFloorGrid(value),
		options.showFloor
	)
	sceneOptions.addSlider(
		t("🎥 Field of view"),
		(value) => sceneManager.setFieldOfView(value),
		45,
		175,
		options.fov,
		1
	)
	sceneOptions.addItem(
        t("🎛 Reset light"),
        () => sceneManager.resetLight()
	)

	sceneOptions.addItem(
		t("🔄 Reset app settings"),
		() => {
			localStorage.clear()

			var cookies = document.cookie.split(";");
			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i];
				var eqPos = cookie.indexOf("=");
				var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
				document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
			}
			setTimeout(() => {
				window.location.reload()
			},100)
		}
	)
	
	if (!window.isWebView) {
		sceneOptions.addItem(
			t("🤖 Update app"),
			() => window.location.reload()
		)
	}


	if (!window.isWebView) {
		const appOptions = optionsMenu.addTab(t("Apps"))

		appOptions.addItem(
			`<span class="link"><img src="https://img.icons8.com/windows/32/000000/apple-app-store--v2.png"/> iOS</span>`,
			() => window.open("https://apps.apple.com/app/justsketchme/id1543663234")
		)
		appOptions.addItem(
			`<span class="link"><img src="https://img.icons8.com/windows/32/000000/mac-os.png"/> Mac</span>`,
			() =>
				window.open(
					"https://justsketchme-apps.fra1.cdn.digitaloceanspaces.com/justsketchme.dmg"
				)
		)
		appOptions.addItem(
			`<span class="link"><img src="https://img.icons8.com/windows/32/000000/android-os.png"/> Android</span>`,
			() => window.open("https://justsketch.me/docs/#android")
		)
		appOptions.addItem(
			`<span class="link"><img src="https://img.icons8.com/windows/32/000000/windows-10.png"/> Windows</span>`,
			() => window.open("https://justsketch.me/docs/#windows")
		)
	}

	const accountOptions = optionsMenu.addTab(t("Account"))
	if (!window.isWebView && license.valid) {
		accountOptions.addItem(t("🪵 Log out"), () => {
			sceneManager.clearScene()
			license.clear()
		})
	}

	if (license.valid) {
		accountOptions.addItem(`
		<div >
		${t('🖥 Activate on other devices with:')}
		<br><b style="padding: 0 20px" class="selectable">${license.key}</b>
		<br><small style="padding: 0 20px;">${t('Up to 3 devices')}</small>
		</div>
		`,
		() => copyToClipboard(t('License key'), license.key))
	}

	accountOptions.addItem(t("🐞 Report a bug"), () =>
		window.open("https://justsketch.me/bugs/")
	)

	accountOptions.addItem(t("🧜‍♂️ Suggest a feature"), () =>
		window.open("https://justsketchme.nolt.io")
	)

	accountOptions.addItem(t("📜 Documentation"), () =>
		window.open("https://justsketch.me/docs/")
	)

	accountOptions.addItem(t("❓ FAQ"), () =>
		window.open("https://justsketch.me/faq/")
	)

	if (store.state.user.update_url) {
		accountOptions.addItem("Update payment information", () =>
			window.open(store.state.user.update_url)
		)
	}
	if (store.state.user.cancel_url) {
		accountOptions.addItem("Cancel subscription", () => {
			const confirm = prompt(`Warning!
                \nBy cancelling you will lose your saved data.
                \nIf you would like to pause your subscription or get a discounted rate, email us at hello@justsketch.me and we'll try help you out. 
                \nType "yes" to confirm your cancellation.`)
			if (confirm == "yes") {
				window.open(store.state.user.cancel_url)
			}
		})
	} else {
		if (!window.isWebView && license.valid) {
			accountOptions.addItem(t("💳 Manage subscription"), () =>
				window.open("https://justsketch.me/faq#cancel-subscription")
			)
		}
	}

	optionsMenu.buildMenu()
}

const buildExportMenu = () => {
	const exportMenu = new CoolGUI("Export", "#save-menu")
	exportMenu.addButton(
		t("🖼️ Take screenshot"),
		() => sceneManager.takeScreenshot(),
		"yellow"
	)
	exportMenu.addButton(
		`${star()}${t('Export model as .dae')}`,
		license.valid ? () => sceneManager.selectedManager.character.prepareExport("dae") : license.check
	)
	exportMenu.addButton(
		`${star()}${t('Export model as .obj')}`,
		license.valid ? () => sceneManager.selectedManager.character.prepareExport("obj") : license.check
	)
	exportMenu.buildMenu()
}

export const buildPoseMenu = () => {

	const poseMenu = new CoolGUI(t(`Poses`), "#pose-menu")

	const savedPoseMenu = poseMenu.addTab(t("Saved poses"))

	savedPoseMenu.addButton(
		t(`Save pose`),
		license.valid ? () => {
			if (!connected) {
				showMessage("Internet connection required for cloud features", 4000, 300)
			} else {
				openSavePosePopup()
			}
		}: license.check,
		"pink")

	if (!window.isWebView) {
		savedPoseMenu.addSideBySideButton(
			t('Export pose'),
			license.valid ? exportPose : license.check,
			t('Open pose'),
			license.valid ? () => importPose() : license.check
		)
	}

	Object.entries(store.state.savedPoses).forEach((entry) => {
		const poseInfo = entry[1]

		const params = {
			category: poseInfo.category,
			name: poseInfo.name,
			loadPose: () => {
				if (!connected) {
					showMessage("Internet connection required for cloud features", 4000, 300)
				} else {
					api.getPose(license.key, poseInfo.id).then((pose) => {
						console.log(pose)
						loadPose(poseInfo.name, poseInfo.category, JSON.parse(pose.values))
					})
				}
			},
			deletePose: () => {
				if (!connected) {
					showMessage("Internet connection required for cloud features", 4000, 300)
				} else {
					if (!confirm(`Delete ${poseInfo.name}?`)) return
					api.deletePose(license.key, poseInfo.id)
				}
			},
		}
		if (params.category) {
			savedPoseMenu.addAccordion(
				params.category,
				params.name,
				params.loadPose,
				params.deletePose
			)
		} else {
			savedPoseMenu.addItem(params.name, params.loadPose, params.deletePose)
		}
	})

	let bodyPoses = poseLibrary.body

	if (store.state.poseLibrary.body) {
		bodyPoses = store.state.poseLibrary.body
	}

	const poseLibraryMenu = poseMenu.addTab(`${star()}${t('Pose library')}`)


	bodyPoses.forEach((pose) => {
		if (!pose.category) {
			poseLibraryMenu.addItem(t(pose.name), () => loadPose(pose.name, pose.category, JSON.parse(pose.values)))
		} else {
			if (license.valid) {
				poseLibraryMenu.addAccordion(
					pose.category,
					pose.name,
					() => {
						loadPose(pose.name, pose.category, JSON.parse(pose.values))
					}
				)
			} else {
				poseLibraryMenu.addAccordion(
					pose.category,
					pose.name,
					license.check,
					null,
					"locked"
				)
			}
			
		}
	})


	poseMenu.buildMenu()
}

export const buildHandPoseMenu = () => {
	const handPoseMenu = new CoolGUI(`${star()}${t('Hand pose library')}`, "#hand-pose-menu")

	let handPoses = poseLibrary.hand

	if (store.state.poseLibrary.hand) {
		handPoses = store.state.poseLibrary.hand
	}

	handPoses.forEach((pose) => {
		handPoseMenu.addDoubleButton(
			t(pose.name),
			t("Left"),
			license.valid ? () => loadHandLibraryPose(JSON.parse(pose.values), false) : license.check,
			t("Right"),
			license.valid ? () => loadHandLibraryPose(JSON.parse(pose.values), true) : license.check,
		)
		handPoseMenu.buildMenu()
	})
}

export const buildSceneMenu = () => {
	const sceneMenu = new CoolGUI(`${star()}${t('Scenes')}`, "#scene-menu")

	sceneMenu.addButton(
		t("Save scene"),
		license.valid ? () => {
			if (!connected) {
				showMessage("Internet connection required for cloud features", 4000, 300)
			} else {
				openSaveScenePopup()
			}
		} : license.check,
		"pink"
	)

	if (!window.isWebView) {
		sceneMenu.addSideBySideButton(
			t('Export scene'),
			license.valid ? exportScene : license.check,
			t('Import scene'),
			license.valid ? () => importScene() : license.check
		)
	}

	Object.entries(store.state.savedScenes).forEach((entry) => {
		const sceneInfo = entry[1]

		const params = {
			category: sceneInfo.category,
			name: sceneInfo.name,
			loadScene: () => {
				if (!connected) {
					showMessage("Internet connection required for cloud features", 4000, 300)
				} else {
					api.getScene(license.key, sceneInfo.id).then((scene) => {
						console.log(scene)
						sceneManager.loadScene(
							sceneInfo.name,
							sceneInfo.category,
							JSON.parse(scene.values)
						)
						closeAllMenus()
					})
				}
			},
			deleteScene: () => {
				if (!connected) {
					showMessage("Internet connection required for cloud features", 4000, 300)
				} else {
					if (!confirm(`Delete ${sceneInfo.name}?`)) return
					api.deleteSavedScene(license.key, sceneInfo.id)
				}
			},
		}

		if (params.category) {
			sceneMenu.addAccordion(
				params.category,
				params.name,
				params.loadScene,
				params.deleteScene
			)
		} else {
			sceneMenu.addItem(params.name, params.loadScene, params.deleteScene)
		}
	})

	sceneMenu.buildMenu()
}

export const buildMenus = () => {
	console.log('Building all menus')
	buildCharacterMenu()
	buildPropsMenu()
	buildUploadMenu()
	buildUpgradeMenu()
	buildOptionsMenu()
	buildExportMenu()
	buildHandPoseMenu()
	buildPoseMenu()
	buildSceneMenu()
}
