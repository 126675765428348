const props = [
  {
    name: 'Box',
    id: 'box',
    icon: 'box',
    menuItem: true,
  },
  {
    name: 'Cylinder',
    id: 'cylinder',
    icon: 'database',
    menuItem: true,
  },
  {
    name: 'Cone',
    id: 'cone',
    icon: 'triangle',
    menuItem: true,
  },
  {
    name: 'Sphere',
    id: 'sphere',
    icon: 'circle',
    menuItem: true,
  },
  {
    name: 'Torus',
    id: 'torus',
    icon: 'disc',
    menuItem: true,
  },
  {
    name: 'Upload image',
    id: 'image',
    icon: 'image',
    menuItem: false,
  },
  {
    name: 'Upload prop (.obj)',
    id: 'model',
    icon: 'feather',
    menuItem: false,
  }
];

export default props;
