class CoolGUI {
	constructor(name, parentSelector) {
		this.items = [];
		this.tabs = [];
		this.activeTab = 0;
		this.name = name;
		this.parentElement = document.querySelector(parentSelector);
	}

	addTab(name) {
		const container = {
			name,
			items: [],
		};

		container.addItem = (name, onclick, deleteClick, className="") =>
			this.addItem(name, onclick, deleteClick, className, container);
		
		container.addText = (title, className) =>
			this.addText(title, className, container);

		container.addButton = (name, onclick, color) =>
			this.addButton(name, onclick, color, container);
		
		container.addDoubleButton = (name, label1, onclick1, label2, onclick2) =>
			this.addDoubleButton(name, label1, onclick1, label2, onclick2, container);
		
		container.addSideBySideButton = (label1, onclick1, label2, onclick2) =>
			this.addSideBySideButton(label1, onclick1, label2, onclick2, container);

		container.addSwitch = (name, onchange, initial) =>
			this.addSwitch(name, onchange, initial, container);

		container.addSlider = (name, onchange, min, max, initial, step) =>
			this.addSlider(name, onchange, min, max, initial, step, container);

		container.addAccordion = (header, name, onclick, deleteClick, className="") =>
			this.addAccordion(header, name, onclick, deleteClick, className, container);
		
		container.addTextInput = (title, placeholder, submitButton, onsubmit) =>
			this.addTextInput(title, placeholder, submitButton, onsubmit, container);

		this.tabs.push(container);

		return container;
	}

	addItem(name, onclick, deleteClick, className="", container = this) {
		container.items.push({
			name,
			onclick,
			deleteClick,
			className,
			type: 'action',
		});
	}

	addText(title, className, container = this) {
		container.items.push({
			title,
			className,
			type: 'text',
		});
	}

	addButton(name, onclick, color='mint', container = this) {
		container.items.push({
			name,
			onclick,
			color,
			type: 'button',
		});
	}

	addDoubleButton(name, label1, onclick1, label2, onclick2, container = this) {
		container.items.push({
			name,
			label1,
			onclick1,
			label2,
			onclick2,
			type: 'doubleButton',
		});
	}

	addSideBySideButton(label1, onclick1, label2, onclick2, container = this) {
		container.items.push({
			label1,
			onclick1,
			label2,
			onclick2,
			type: 'sideBySideButton',
		});
	}

	addSwitch(name, onchange, initial, container = this) {
		container.items.push({
			name,
			onchange,
			initial,
			type: 'switch',
		});
	}

	addSlider(name, onchange, min, max, initial, step, container = this) {
		container.items.push({
			name,
			onchange,
			min,
			max,
			initial,
			step,
			type: 'slider',
		});
	}

	addAccordion(header, name, onclick, deleteClick, className="", container = this) {
		container.items.push({
			header,
			name,
			className,
			onclick,
			deleteClick,
			className,
			type: 'accordion',
		});
	}

	addTextInput(title, placeholder, submitButton, onsubmit, container = this) {
		container.items.push({
			title,
			placeholder,
			submitButton,
			onsubmit,
			type: 'input',
		})
	}

	buildMenu() {
		while (this.parentElement.firstChild) {
			this.parentElement.removeChild(this.parentElement.firstChild);
		}

		this.parentElement.classList.add('panel');
		const headingElement = document.createElement('p');
		headingElement.classList.add('panel-heading');
		headingElement.innerHTML = this.name;
		this.parentElement.appendChild(headingElement);

		if (this.tabs.length > 0) {

			const tabsElement = document.createElement('nav');
			tabsElement.classList.add('panel-tabs');
			const appendedTabs = this.parentElement.appendChild(tabsElement);

			this.tabs.forEach((container, index) => {
				const tabElement = document.createElement('a');
				tabElement.onclick = () => {
					this.activeTab = index;
					this.buildMenu();
				}

				tabElement.innerHTML = container.name;
				appendedTabs.appendChild(tabElement);

				if (index == this.activeTab) {
					tabElement.classList.add('is-active');
					this.buildItems(container.items);
				}
			});
		} else {
			this.buildItems(this.items);
		}
	}

	buildItems(items) {
		if (items.length == 0) {
			let childElement = document.createElement('a');
			childElement.classList.add('panel-block');
			childElement.innerHTML = 'Empty...';
			this.parentElement.appendChild(childElement);
		}
		items.forEach(item => {
			if (item.type == 'slider') {
				let childElement = document.createElement('div');
				childElement.classList.add('panel-block', 'stacked');

				let titleElement = document.createElement('label');
				titleElement.innerHTML = item.name;

				let sliderElement = document.createElement('input');
				sliderElement.setAttribute('type', 'range');
				sliderElement.setAttribute('min', item.min);
				sliderElement.setAttribute('max', item.max);
				sliderElement.setAttribute('value', item.initial);
				sliderElement.setAttribute('step', item.step);
				sliderElement.onchange = () => item.onchange(sliderElement.value);

				childElement.appendChild(titleElement);
				childElement.appendChild(sliderElement);
				this.parentElement.appendChild(childElement);
			}
			if (item.type == 'switch') {
				let childElement = document.createElement('div');
				childElement.classList.add('panel-block');

				let titleElement = document.createElement('label');
				titleElement.innerHTML = item.name;

				let switchElement = document.createElement('label');
				switchElement.classList.add('switch');
				let switchInput = document.createElement('input');
				switchInput.setAttribute('type', 'checkbox');
				if (item.initial) {
					switchInput.setAttribute('checked', item.initial);
				}
				switchInput.onchange = () => item.onchange(switchInput.checked);
				let switchSpan = document.createElement('span');
				switchSpan.classList.add('slider', 'round');
				switchElement.appendChild(switchInput);
				switchElement.appendChild(switchSpan);

				childElement.appendChild(titleElement);
				childElement.appendChild(switchElement);
				this.parentElement.appendChild(childElement);
			}
			if (item.type == 'button') {
				let childElement = document.createElement('a');
				childElement.classList.add('panel-block');

				let buttonElement = document.createElement('button');
				buttonElement.classList.add('button');
				buttonElement.classList.add(item.color);
				buttonElement.innerHTML = item.name;
				buttonElement.onclick = item.onclick;

				childElement.appendChild(buttonElement);
				this.parentElement.appendChild(childElement);
			}
			if (item.type == 'doubleButton') {
				let childElement = document.createElement('div');
				childElement.classList.add('panel-block');
				childElement.classList.add('double-button');

				let titleSpan = document.createElement('span');
				titleSpan.innerText = item.name;

				let buttonElement1 = document.createElement('button');
				buttonElement1.classList.add('button');
				buttonElement1.classList.add('pink');
				buttonElement1.innerHTML = item.label1;
				buttonElement1.onclick = item.onclick1;

				let buttonElement2 = document.createElement('button');
				buttonElement2.classList.add('button');
				buttonElement2.classList.add('mint');
				buttonElement2.innerHTML = item.label2;
				buttonElement2.onclick = item.onclick2;

				childElement.appendChild(titleSpan);
				childElement.appendChild(buttonElement1);
				childElement.appendChild(buttonElement2);
				this.parentElement.appendChild(childElement);
			}
			if (item.type == 'sideBySideButton') {
				let childElement = document.createElement('div');
				childElement.classList.add('panel-block');

				let buttonElement1 = document.createElement('button');
				buttonElement1.classList.add('button');
				buttonElement1.classList.add('mint');
				buttonElement1.classList.add('small');
				buttonElement1.innerHTML = item.label1;
				buttonElement1.onclick = item.onclick1;

				let buttonElement2 = document.createElement('button');
				buttonElement2.classList.add('button');
				buttonElement2.classList.add('mint');
				buttonElement2.classList.add('small');
				buttonElement2.innerHTML = item.label2;
				buttonElement2.onclick = item.onclick2;

				childElement.appendChild(buttonElement1);
				childElement.appendChild(buttonElement2);
				this.parentElement.appendChild(childElement);
			}
			if (item.type == 'accordion') {
				let menuElement = this.parentElement.querySelector(`.accordion[accordion-id="${string_to_slug(item.header)}"]`);
				let itemsElement = this.parentElement.querySelector(`.accordion[accordion-id="${string_to_slug(item.header)}"] .items`);

				if(!menuElement) {
					menuElement = document.createElement('div');
					menuElement.classList.add('accordion');
					if (item.className) {
						menuElement.classList.add(item.className)
					}
					
					menuElement.setAttribute('accordion-id', string_to_slug(item.header));
					
					let headerElement = document.createElement('p');
					headerElement.classList.add('header');
					headerElement.innerHTML = item.header;

					itemsElement = document.createElement('div');
					itemsElement.classList.add('items');

					headerElement.onclick = (event) => {
						event.stopPropagation();
						itemsElement.classList.toggle("active");
						menuElement.classList.toggle("active");
					}
					
					menuElement.appendChild(headerElement);
					menuElement.appendChild(itemsElement);
					this.parentElement.appendChild(menuElement);
				}
				


				let childElement = document.createElement('a');
				childElement.classList.add('panel-block');
				childElement.innerHTML = item.name;
				childElement.onclick = item.onclick;

				const appendedChild = itemsElement.appendChild(childElement);
				if (item.deleteClick != null) {
					const deleteElement = document.createElement('button');
					deleteElement.classList.add('delete');

					deleteElement.onclick = (event) => {
						event.stopPropagation();
						item.deleteClick();
					}
					appendedChild.appendChild(deleteElement);
				}
			}
			if (item.type == 'action') {
				let childElement = document.createElement('a');
				childElement.classList.add('panel-block');
				if (item.className) {
					childElement.classList.add(item.className);
				}
				childElement.innerHTML = item.name;
				childElement.onclick = item.onclick;

				const appendedChild = this.parentElement.appendChild(childElement);
				if (item.deleteClick != null) {
					const deleteElement = document.createElement('button');
					deleteElement.classList.add('delete');

					deleteElement.onclick = (event) => {
						event.stopPropagation();
						item.deleteClick();
					}
					appendedChild.appendChild(deleteElement);
				}
			}
			if (item.type == 'text') {
				let childElement = document.createElement('div');
				childElement.classList.add('panel-block');
				childElement.classList.add(item.className);
				childElement.innerHTML = item.title;

				const appendedChild = this.parentElement.appendChild(childElement);
			}
			if (item.type == 'input') {
				let childElement = document.createElement('div');
				childElement.classList.add('panel-block');
				childElement.classList.add('license-key');

				let titleElement = document.createElement('h4');
				titleElement.innerHTML = item.title;

				let inputElement = document.createElement('input');
				inputElement.type = 'text';
				inputElement.classList.add('text-input');
				inputElement.placeholder = item.placeholder;

				let buttonElement = document.createElement('button');
				buttonElement.innerText = item.submitButton;
				buttonElement.classList.add('button');
				buttonElement.classList.add('mint');

				buttonElement.onclick = () => item.onsubmit(inputElement.value);

				childElement.appendChild(titleElement);
				childElement.appendChild(inputElement);
				childElement.appendChild(buttonElement);
				this.parentElement.appendChild(childElement);
			}
		});
	}
}

const string_to_slug = str => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaaeeeeiiiioooouuuunc------";

    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
}

export default CoolGUI;