class Loader {
    constructor() {
        this.element = document.querySelector('#loading');
        this.loadingList = document.querySelector("#loading-list");
        this.errorMenu = document.querySelector(`#loading-error`);
        this.activeLoaders = [];
    }

    startLoading (id, title) {
        this.activeLoaders.push(id);
        this.element.classList.add('is-active');

        let loadingItem = document.createElement('p');
        loadingItem.innerHTML = title;
        loadingItem.classList.add('loading-item');
        loadingItem.setAttribute('item-id', id);
        this.loadingList.appendChild(loadingItem);
    }

    setLoadingTitle (id, title) {
        let loadingItem = document.querySelector(`.loading-item[item-id='${id}']`);
        if (loadingItem) {
            loadingItem.innerHTML = title;
        }
    }

    setLoadingFailed (id, errorMessage) {
        this.errorMenu.innerHTML = errorMessage;
        this.errorMenu.style.visibility = 'visible'
        setTimeout(() => {
        this.errorMenu.style.visibility = 'hidden'
        this.stopLoading(id);
        }, 2000);
    }

    stopLoading (id)  {

        const loadingItem = document.querySelector(`.loading-item[item-id='${id}']`);
        if (loadingItem) {
            loadingItem.remove();
        }
        this.activeLoaders.splice(this.activeLoaders.indexOf(id), 1);

        if (this.activeLoaders.length == 0) {
            this.element.classList.remove('is-active');
        }
    }
}

export default Loader;