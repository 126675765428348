import { buildMenus, buildPoseMenu, buildPropsMenu, buildSceneMenu } from './helpers/Menus';

class Store {
    constructor() {
        this.state = {
            savedPoses: {},
            savedScenes: {},
            poseLibrary: {},
            propPacks: [],
            user: {},
        };
    }

    setState(newState) {
        if (newState.savedPoses) {
            this.state.savedPoses = newState.savedPoses;
            buildPoseMenu()
        } else if (newState.savedScenes) {
            this.state.savedScenes = newState.savedScenes;
            buildSceneMenu()
        } else if (newState.poseLibrary) {
            this.state.poseLibrary = newState.poseLibrary;
            buildPoseMenu()
        } else if (newState.user) {
            this.state.user = newState.user;
            buildMenus()
        } else if (newState.propPacks) {
            this.state.propPacks = newState.propPacks;
            buildPropsMenu()
        } else {
            buildMenus()
        }

        // console.log("[State updated]:", this.state);
    }
}

export default Store;