import * as THREE from 'three';


const material = new THREE.MeshBasicMaterial({
	depthTest: false,
	transparent: false,
	opacity: 0.7,
});

const geometry = new THREE.SphereBufferGeometry(3, 12, 12);

class Joint {
	constructor(size = 1, color, name = "joint", characterSize = 1, type=null, axis=null) {
		
		const joint = new THREE.Mesh(geometry, material.clone());
		
		joint.scaleModifier = window.innerWidth > 450? 1 : 1.5;
		window.addEventListener('resize', () => {
			joint.scaleModifier = window.innerWidth > 450? 1 : 1.5;
			joint.resetScale();
		});
		
		joint.name = name;
		joint.selected = false;
		joint.defaultColor = color;
		joint.type = type;
		joint.size = size;
		joint.axis = axis;

		if (joint.type == "selector") {
			const sideModifier = joint.name == "Left" ? -4: 4
			joint.position.set(joint.position.x + sideModifier, joint.position.y, joint.position.z);
		}

		joint.setColor = color => {
			joint.material.color.set(color);
		}

		joint.resetColor = () => {
			joint.setColor(joint.defaultColor);
		}

		joint.reset = () => {
			joint.selected = false;
			joint.resetColor();
		}

		joint.resetScale = () => {
			// This check is catering to some shitty models and should not be used :(
			if (joint.getWorldScale(new THREE.Vector3).x > 5) {
				joint.scale.set(
					joint.scaleModifier / joint.size / characterSize / joint.parent.scale.x / 100,
					joint.scaleModifier / joint.size / characterSize / joint.parent.scale.y / 100,
					joint.scaleModifier / joint.size / characterSize / joint.parent.scale.z / 100,
				);
			} else {
				joint.scale.set(
					joint.scaleModifier / joint.size / characterSize / joint.parent.scale.x,
					joint.scaleModifier / joint.size / characterSize / joint.parent.scale.y,
					joint.scaleModifier / joint.size / characterSize / joint.parent.scale.z
				);
			}
		}


		joint.setColor(joint.defaultColor);

		joint.material.receiveShadow = false;
		joint.material.castShadow = false;
		joint.renderOrder = 1;
		joint.scale.set(
			joint.scaleModifier / joint.size / characterSize,
			joint.scaleModifier / joint.size / characterSize,
			joint.scaleModifier / joint.size / characterSize,
		);

		return joint;
	}
}

export const jointColors = {
	SELECTED_COLOR: 0x00ff00,
	DELETE_COLOR: 0xff0000,
	DESELECTED_COLOR: 0xfffff,
	LIGHT_COLOR: 0xffff00,
	HOVER_COLOR: 0xffffff,
}

export default Joint;