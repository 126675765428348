import { t } from "./Translate"

export const addCharacterToTabs = (character) => {
	const isTouch = "ontouchstart" in document.documentElement

	const menu = document.querySelector("#characters")

	const tab = document.createElement("li")
	tab.setAttribute("id", character.id)

	if (!isTouch) {
		tab.onmouseenter = () => {
			character.hover()
		}

		tab.onmouseleave = () => {
			character.unhover()
		}
	}

	const selectorButton = document.createElement("button")
	selectorButton.title = t("Select")
	const image = document.createElement("img")
	image.setAttribute("src", character.thumbnail)
	selectorButton.appendChild(image)
	selectorButton.onclick = () => {
		character.selectCharacter()
	}

	const deleteButton = document.createElement("button")
	deleteButton.title = t("Delete")
	deleteButton.classList.add("tab-delete")
	deleteButton.innerHTML = `<svg><use xlink:href="images/feather-sprite.svg#trash" /></svg>`
	deleteButton.onclick = (event) => {
		if (confirm(t("Delete?"))) {
			character.delete()
			if (menu.innerHTML != "") {
				menu.firstChild.classList.add("active")
			}
		}
	}

	const flipButton = document.createElement("button")
	flipButton.title = t("Flip")
	flipButton.classList.add("tab-flip")
	flipButton.innerHTML = `<svg><use xlink:href="images/feather-sprite.svg#repeat" /></svg>`
	flipButton.onclick = (event) => {
		character.flip()
	}

	const duplicateButton = document.createElement("button")
	duplicateButton.title = t("Duplicate")
	duplicateButton.classList.add("tab-flip")
	duplicateButton.innerHTML = `<svg><use xlink:href="images/feather-sprite.svg#copy" /></svg>`
	duplicateButton.onclick = (event) => {
		character.duplicate()
	}

	const lockButton = document.createElement("button")
	lockButton.title = t("Lock toggle")
	lockButton.classList.add("tab-toggle")
	lockButton.innerHTML = `<svg class="is-active on"><use xlink:href="images/feather-sprite.svg#unlock" /></svg>
        <svg class="off"><use xlink:href="images/feather-sprite.svg#lock" /></svg>`
	lockButton.onclick = (event) => {
		character.toggleJoints()
		if (character.jointsHidden) {
			tab.classList.add("pink")
			lockButton.querySelector(".on").classList.remove("is-active")
			lockButton.querySelector(".off").classList.add("is-active")
		} else {
			tab.classList.remove("pink")
			lockButton.querySelector(".off").classList.remove("is-active")
			lockButton.querySelector(".on").classList.add("is-active")
		}
	}

	const hideButton = document.createElement("button")
	hideButton.title = t("Hide toggle")
	hideButton.classList.add("tab-toggle")
	hideButton.innerHTML = `<svg class="is-active on"><use xlink:href="images/feather-sprite.svg#eye" /></svg>
        <svg class="off"><use xlink:href="images/feather-sprite.svg#eye-off" /></svg>`
	hideButton.onclick = (event) => {
		character.toggleVisibility()
		if (!character.object.visible) {
			tab.classList.add("grey")
			hideButton.querySelector(".on").classList.remove("is-active")
			hideButton.querySelector(".off").classList.add("is-active")
		} else {
			tab.classList.remove("grey")
			hideButton.querySelector(".off").classList.remove("is-active")
			hideButton.querySelector(".on").classList.add("is-active")
		}
	}

	const colorSelector = document.createElement("input")
	colorSelector.type = "color"
	colorSelector.classList.add("tab-color")
	colorSelector.value = character.color

	colorSelector.onchange = (event) => {
		character.color = event.target.value
		character.setMaterial()
	}

	const putOnGroundButton = document.createElement("button")
	putOnGroundButton.title = t("Place on ground")
	putOnGroundButton.classList.add("tab-flip")
	putOnGroundButton.innerHTML = `<svg><use xlink:href="images/feather-sprite.svg#chevrons-down" /></svg>`
	putOnGroundButton.onclick = (event) => {
		character.putOnGround()
	}

	tab.appendChild(selectorButton)
	tab.appendChild(colorSelector)
	tab.appendChild(flipButton)
	tab.appendChild(duplicateButton)
	tab.appendChild(lockButton)
	tab.appendChild(hideButton)
	tab.appendChild(deleteButton)
	tab.appendChild(putOnGroundButton)

	menu.appendChild(tab)
}

export const addPropToTabs = (prop) => {
	const isTouch = "ontouchstart" in document.documentElement
	const menu = document.querySelector("#characters")

	const tab = document.createElement("li")
	tab.setAttribute("id", prop.id)

	if (!isTouch) {
		tab.onmouseenter = () => {
			prop.hover()
		}
		tab.onmouseleave = () => {
			prop.unhover()
		}
	}

	const selectorButton = document.createElement("button")
	selectorButton.title = t("Select")

	if (prop.icon) {
		selectorButton.innerHTML = `<img src="${prop.icon}" width=20 />`			
	} else {
		selectorButton.innerHTML = `<svg><use xlink:href="images/feather-sprite.svg#${prop.info.icon}" /></svg>`
	}
	
	selectorButton.onclick = () => {
		prop.onTap()
	}

	const colorSelector = document.createElement("input")
	colorSelector.type = "color"
	colorSelector.classList.add("tab-color")
	colorSelector.value = prop.color

	colorSelector.onchange = (event) => {
		prop.color = event.target.value
		prop.setMaterial()
	}

	const duplicateButton = document.createElement("button")
	duplicateButton.title = t("Duplicate")
	duplicateButton.classList.add("tab-flip")
	duplicateButton.innerHTML = `<svg><use xlink:href="images/feather-sprite.svg#copy" /></svg>`
	duplicateButton.onclick = (event) => {
		prop.duplicate()
	}

	const lockButton = document.createElement("button")
	lockButton.title = t("Lock toggle")
	lockButton.classList.add("tab-toggle")
	lockButton.innerHTML = `<svg class="is-active on"><use xlink:href="images/feather-sprite.svg#unlock" /></svg>
        <svg class="off"><use xlink:href="images/feather-sprite.svg#lock" /></svg>`
	lockButton.onclick = (event) => {
		prop.toggleLock()
		if (prop.locked) {
			tab.classList.add("pink")
			lockButton.querySelector(".on").classList.remove("is-active")
			lockButton.querySelector(".off").classList.add("is-active")
		} else {
			tab.classList.remove("pink")
			lockButton.querySelector(".off").classList.remove("is-active")
			lockButton.querySelector(".on").classList.add("is-active")
		}
	}

	const hideButton = document.createElement("button")
	hideButton.title = t("Hide toggle")
	hideButton.classList.add("tab-toggle")
	hideButton.innerHTML = `<svg class="is-active on"><use xlink:href="images/feather-sprite.svg#eye" /></svg>
        <svg class="off"><use xlink:href="images/feather-sprite.svg#eye-off" /></svg>`
	hideButton.onclick = (event) => {
		prop.toggleVisibility()
		if (!prop.object.visible) {
			tab.classList.add("grey")
			hideButton.querySelector(".on").classList.remove("is-active")
			hideButton.querySelector(".off").classList.add("is-active")
		} else {
			tab.classList.remove("grey")
			hideButton.querySelector(".off").classList.remove("is-active")
			hideButton.querySelector(".on").classList.add("is-active")
		}
	}

	const connectButton = document.createElement("button")
	connectButton.title = t("Attach to character")
	connectButton.classList.add("tab-toggle")
	connectButton.classList.add("attach")
	connectButton.innerHTML = `<svg class="is-active on"><use xlink:href="images/feather-sprite.svg#crosshair" /></svg>
        <svg class="off"><use xlink:href="images/feather-sprite.svg#stop-circle" /></svg>`
	connectButton.onclick = (event) => {
		prop.onSecondaryTap()
	}

	const deleteButton = document.createElement("button")
	deleteButton.title = t("Delete")
	deleteButton.classList.add("tab-delete")
	deleteButton.innerHTML = `<svg><use xlink:href="images/feather-sprite.svg#trash" /></svg>`
	deleteButton.onclick = (event) => {
		if (confirm(t("Delete?"))) {
			prop.delete()
			if (menu.innerHTML != "") {
				menu.firstChild.classList.add("active")
			}
		}
	}

	const flipButton = document.createElement("button")
	flipButton.title = t("Flip")
	flipButton.classList.add("tab-flip")
	flipButton.innerHTML = `<svg><use xlink:href="images/feather-sprite.svg#repeat" /></svg>`
	flipButton.onclick = (event) => {
		prop.flip()
	}

	const putOnGroundButton = document.createElement("button")
	putOnGroundButton.title = t("Place on ground")
	putOnGroundButton.classList.add("tab-flip")
	putOnGroundButton.innerHTML = `<svg><use xlink:href="images/feather-sprite.svg#chevrons-down" /></svg>`
	putOnGroundButton.onclick = (event) => {
		prop.putOnGround()
	}

	tab.appendChild(selectorButton)
	tab.appendChild(colorSelector)
	tab.appendChild(flipButton)
	tab.appendChild(duplicateButton)
	tab.appendChild(lockButton)
	tab.appendChild(hideButton)
	tab.appendChild(putOnGroundButton)
	tab.appendChild(connectButton)
	tab.appendChild(deleteButton)

	menu.appendChild(tab)
}

export const updateTab = (object, action) => {
	const element = document.getElementById(object.id)
	action(element)
}

export const clearCharacterTabs = () => {
	const menu = document.querySelector("#characters")
	menu.innerHTML = ""
}

export const removeFromTabs = (object) => {
	if (document.getElementById(object.id)) {
		document.getElementById(object.id).remove()
	}
}
