import jointsPreset, { dragonPresets } from './jointPresets';

export const characters = [
  {
    title: 'Female',
    id: 12,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/female.fbx',
    path: 'models/female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/female.png',
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Male',
    id: 13,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/male.fbx',
    path: 'models/male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/male.png',
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Teen female',
    id: 514,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/teen_female.fbx',
    path: 'models/teen_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/female_teen.png',
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Teen male',
    id: 513,
    src: 'https://justsketchme.nyc3.digitaloceanspaces.com/teen_male.fbx',
    path: 'models/teen_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/male_teen.png',
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Tween female',
    id: 217,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/tween_female.fbx',
    path: 'models/tween_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/female_tween.png',
    joints: jointsPreset,
    jointSize: 0.9,
    defaultPose: 0,
    color: '#59c2ff',
  },
  {
    title: 'Tween male',
    id: 218,
    src: 'https://justsketchme.nyc3.digitaloceanspaces.com/tween_male.fbx',
    path: 'models/tween_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/male_tween.png',
    joints: jointsPreset,
    jointSize: 0.9,
    defaultPose: 0,
    color: '#ff796a',
  },
  {
    title: 'Child (older)',
    id: 219,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/child_old.fbx',
    path: 'models/child_old.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/child.png',
    joints: jointsPreset,
    jointSize: 0.8,
    defaultPose: 0,
    color: '#64c195',
  },
  {
    title: 'Child (younger)',
    id: 219,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/child.fbx',
    path: 'models/child.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/child.png',
    joints: jointsPreset,
    jointSize: 0.8,
    defaultPose: 0,
    color: '#64c195',
  },
  {
    title: 'Baby',
    id: 512,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/babet.fbx',
    path: 'models/babet.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/baby.png',
    joints: jointsPreset,
    jointSize: 0.6,
    defaultPose: 0,
    color: '#fcc777',
  },
  {
    title: 'Mermaid',
    id: 10,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/mermaid.fbx',
    path: 'models/mermaid.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/mermaid.png',
    joints: jointsPreset,
    locked: true,
    category: 'Fantasy',
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Merman',
    id: 11,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/merman.fbx',
    path: 'models/merman.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/merman.png',
    joints: jointsPreset,
    locked: true,
    category: 'Fantasy',
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Skeleton',
    id: 311,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/skeleton.fbx',
    path: 'models/skeleton.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/skeleton.png',
    category: 'Fantasy',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#929292',
  },
  {
    title: 'Dragon',
    id: 316,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/dragon.fbx',
    path: 'models/dragon.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/dragon.png',
    category: 'Fantasy',
    locked: true,
    joints: dragonPresets,
    color: '#544545',
  },
  {
    title: 'Art mannequin',
    id: 4,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/art_mannequin.fbx',
    path: 'models/art_mannequin.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/mannequin.png',
    category: 'Other',
    joints: jointsPreset,
    locked: true,
    defaultPose: 0,
    color: '#ffe29b',
  },
  {
    title: 'Dog',
    id: 1234,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/dog.fbx',
    path: 'models/dog.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/dog.png',
    category: 'Other',
    locked: true,
    joints: jointsPreset,
    jointSize: 0.7,
    centeredOnly: true,
    color: '#fcc777',
  },
  {
    title: 'Cat',
    id: 1235,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/cat.fbx',
    path: 'models/cat.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/cat.png',
    category: 'Other',
    locked: true,
    joints: jointsPreset,
    jointSize: 0.7,
    centeredOnly: true,
    color: '#fcc777',
  },
  {
    title: 'Female v1',
    id: 0,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/xbot.fbx',
    path: 'models/xbot.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/female_old.png',
    category: 'Other',
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Male v1',
    id: 1,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/ybot.fbx',
    path: 'models/ybot.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/male_old.png',
    category: 'Other',
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Anime female v1',
    id: 89,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/anime_female.fbx',
    path: 'models/anime_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/female_anime.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Anime male v1',
    id: 465,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/anime_male.fbx',
    path: 'models/anime_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/male_anime.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Anime female v2',
    id: 78,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/anime_female_v2.fbx',
    path: 'models/anime_female_v2.fbx',
    scale: 0.9,
    thumbnail: 'images/characters/anime_female_v2.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Anime male v2',
    id: 79,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/anime_male_v2.fbx',
    path: 'models/anime_male_v2.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/anime_male_v2.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Stylized female',
    id: 81,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/stylistic_female.fbx',
    path: 'models/stylistic_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/stylistic_female.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Stylized male',
    id: 82,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/stylistic_male.fbx',
    path: 'models/stylistic_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/stylistic_male.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Chibi male',
    id: 466,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/chibi_male.fbx',
    path: 'models/chibi_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/chibi_male.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f3b9da',
  },
  {
    title: 'Chibi female',
    id: 467,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/chibi_female.fbx',
    path: 'models/chibi_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/chibi_female.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f3b9da',
  },
  {
    title: 'Male',
    id: 565,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/base_male.fbx',
    path: 'models/base_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/base_male.png',
    category: 'Realistic',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Female',
    id: 566,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/base_female.fbx',
    path: 'models/base_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/base_female.png',
    category: 'Realistic',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Chubby male',
    id: 573,
    path: 'models/chubby_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/chubby_male.png',
    category: 'Realistic',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Chubby female',
    id: 574,
    path: 'models/chubby_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/chubby_female.png',
    category: 'Realistic',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Skinny male',
    id: 683,
    path: 'models/skinny_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/skinny_male.png',
    category: 'Realistic',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Skinny female',
    id: 684,
    path: 'models/skinny_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/skinny_female.png',
    category: 'Realistic',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Muscular male',
    id: 688,
    path: 'models/muscular_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/muscular_male.png',
    category: 'Realistic',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Muscular female',
    id: 689,
    path: 'models/muscular_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/muscular_female.png',
    category: 'Realistic',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Superhero male',
    id: 665,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/superhero_male.fbx',
    path: 'models/superhero_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/superhero_male.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Superhero female',
    id: 666,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/superhero_female.fbx',
    path: 'models/superhero_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/superhero_female.png',
    category: 'Stylised',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Lycan male',
    id: 165,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/lycan_male.fbx',
    path: 'models/lycan_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/lycan_male.png',
    category: 'Fantasy',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Lycan female',
    id: 166,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/lycan_female.fbx',
    path: 'models/lycan_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/lycan_female.png',
    category: 'Fantasy',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
  {
    title: 'Large male',
    id: 673,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/overweight_male.fbx',
    path: 'models/overweight_male.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/overweight_male.png',
    category: 'Other',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#f58d74',
  },
  {
    title: 'Large female',
    id: 672,
    src: 'https://justsketchme.nyc3.cdn.digitaloceanspaces.com/overweight_female.fbx',
    path: 'models/overweight_female.fbx',
    type: 'fbx',
    thumbnail: 'images/characters/overweight_female.png',
    category: 'Other',
    locked: true,
    joints: jointsPreset,
    defaultPose: 0,
    color: '#59c2c8',
  },
];

export const testCharacter = {
  title: 'Test character',
  id: 999,
  src: null,
  path: null,
  type: 'fbx',
  thumbnail: 'images/characters/mannequin.png',
  joints: jointsPreset,
  color: '#7ecfd4',
};

export default characters;