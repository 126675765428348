import * as THREE from 'three';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import Stats from 'three/examples/jsm/libs/stats.module.js';

import { sceneManager } from './index';
import { scene } from './SceneManager';
import { buildMenus } from './helpers/Menus';
import Joint from './sceneSubjects/Joint';
import { characters, testCharacter } from './constants/characters';
import { Vector3 } from 'three';

class AdminTools {
    constructor() {
        this.active = false;
        this.stats = null;

        this.activateAdminMode = (textBuffer) => {
            if (this.active) return;

            if (textBuffer.join("") == "iddqd" || textBuffer.join("") == "idkfa") {
                this.active = true;
                license.valid = true;
                buildMenus();
                this.buildMenu();
                this.stats = new Stats();
                document.body.appendChild(this.stats.dom);
            }
        };
        this.keyMapper([this.activateAdminMode,]);

        this.options = {
            test: () => {
                console.log(sceneManager.getSceneState())
                localStorage.setItem('sceneState', JSON.stringify(sceneManager.getSceneState()))
            },
            printPose: () => {
                const pose = sceneManager.selectedManager.character.getPose();
                this.copyToClipboard(JSON.stringify(pose));
            },
            printHandPose: () => {
                const pose = sceneManager.selectedManager.character.getPose();
                Object.keys(pose).forEach(key => {

                    if (!key.includes('Hand')) {
                        delete pose[key];
                    }
                    if (key.includes('RightHand')) {
                        const leftKey = key.replace("Right", "Left");
                        pose[key] = {
                            x: pose[leftKey].x,
                            y: -pose[leftKey].y,
                            z: -pose[leftKey].z,
                        }
                    }
                });
                this.copyToClipboard(JSON.stringify(pose));
            },
            uploadFbx: () => {
                const input = document.createElement("input");
                input.type = "file";

                input.addEventListener("change", event => {
                    if (!event.target.files.length) {
                        return false;
                    }
                    const fileList = event.target.files;
                    const reader = new FileReader();
                    reader.readAsDataURL(fileList[0]);
                    reader.onload = (() => {
                        return e => {
                            testCharacter.src = e.target.result;
                            testCharacter.path = e.target.result;
                            sceneManager.addCharacterToScene({info: testCharacter});
                        }
                    })(fileList[0]);
                }, false);
                input.click();
            },
            uploadObj: () => {
                sceneManager.addPropToScene({type: 'model', scale: new Vector3(1,1,1)})
            },
            buildInstancedMesh: () => {
                var geometry = new THREE.SphereBufferGeometry(5);
                var material = new THREE.MeshPhongMaterial({ flatShading: true });

                var count = 250;

                var mesh = new THREE.InstancedMesh(geometry, material, count);

                var dummy = new THREE.Object3D();

                let index = 0;
                for (let i = -50; i < 50; i++) {
                    for (let j = -50; j < 50; j++) {
                        console.log("Instanced");
                        dummy.position.set(i * 10, 1, j * 10);

                        dummy.updateMatrix();
                        index++;
                        mesh.setMatrixAt(index, dummy.matrix);

                    }
                }

                scene.add(mesh);
            },
            buildPoints: () => {
               

            },
            buildJoints: () => {
                for (let i = -50; i < 50; i++) {
                    for (let j = -50; j < 50; j++) {
                        const joint = new Joint(1, 0xfff00, 'test');
                        joint.position.set(
                            i * 10, 1, j * 10
                        );

                        scene.add(joint);
                    }
                }
            },
            buildModels: () => {
                for (let i = -3; i < 3; i++) {
                    for (let j = -5; j < 5; j++) {
                        sceneManager.addCharacterToScene({info: characters[22], position: new THREE.Vector3(i * 50, 1, j * 50)})
                    }
                }
            },
        };
    }

    buildMenu() {
        const gui = new GUI();
        gui.add(this.options, 'test')
        const libraryTools = gui.addFolder('Library tools');
        libraryTools.add(this.options, 'printPose').name('Print pose');
        libraryTools.add(this.options, 'printHandPose').name('Print hand pose');

        const tests = gui.addFolder('Tests');
        tests.add(this.options, 'uploadFbx').name('Test fbx');
        tests.add(this.options, 'uploadObj').name('Test obj');
        tests.add(this.options, 'buildInstancedMesh').name('Build IMesh');
        tests.add(this.options, 'buildPoints').name('Build points');
        tests.add(this.options, 'buildJoints').name('Build joints');
        tests.add(this.options, 'buildModels').name('Build models');
        
    }

    copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(function () {
            alert('Async: Copying to clipboard was successful!');
        }, function (err) {
            alert('Async: Could not copy text: ', err);
        });
    }

    keyMapper(callbackList) {
        const keystrokeDelay = 1000;
        const eventType = 'keydown';

        let state = {
            buffer: [],
            lastKeyTime: Date.now()
        };

        document.addEventListener(eventType, event => {
            const key = event.key;
            const currentTime = Date.now();
            let textBuffer = [];

            if (currentTime - state.lastKeyTime > keystrokeDelay) {
                textBuffer = [key];
            } else {
                textBuffer = [...state.buffer, key];
            }

            state = { buffer: textBuffer, lastKeyTime: currentTime };
            callbackList.forEach(callback => callback(textBuffer));
        });
    }
}

export default AdminTools;