import { sceneManager, api, store, setIsInactive, setIsActive } from "../index";
import { showMessage } from "../Message";
import { t } from "./Translate";

export const quickSave = () => {
  // Saving full scene in localstorage
  setTimeout(() => {
    localStorage.setItem(
      "sceneState",
      JSON.stringify(sceneManager.getSceneState())
    );
  }, 500);
};

export const openSavePosePopup = () => {
  document.querySelector("#pose-category").value =
    sceneManager.lastPoseCategory || "";
  document.querySelector("#pose-name").value = sceneManager.lastPoseName;
  setIsActive("#save-pose-popup");
};

export const saveNewPose = () => {
  const category = document.querySelector("#pose-category").value;
  const name = document.querySelector("#pose-name").value;
  if (name) {
    savePose(category || undefined, name);
    setIsInactive("#save-pose-popup");
  }
};

export const saveExistingPose = () => {
  savePose(sceneManager.lastPoseCategory, sceneManager.lastPoseName);
};

export const savePose = (category, name) => {
  const savedPose = sceneManager.selectedManager.character.getPose();

  sceneManager.lastPoseName = name;
  sceneManager.lastPoseCategory = category;

  const sameNamedPose = Object.entries(store.state.savedPoses).filter(
    pose => pose[1].category == category && pose[1].name == name
  );

  if (sameNamedPose.length > 0) {
    const overwrite = confirm(`${t("Overwrite")} ${name}?`);
    if (overwrite) {
      api
        .savePose(
          license.key,
          name,
          JSON.stringify(savedPose),
          category,
          sameNamedPose[0][1].id
        )
        .then(() => showMessage(`${name} ${t("updated")}`, 2000, 300));
    }
  } else {
    api
      .savePose(license.key, name, JSON.stringify(savedPose), category)
      .then(() => showMessage(`${name} ${t("saved")}`, 2000, 300));
  }
};

export const loadPose = (name, category, pose) => {
  sceneManager.lastPoseName = name;
  sceneManager.lastPoseCategory = category;
  sceneManager.selectedManager.character.setPose(pose);
};

export const loadHandLibraryPose = (pose, right) => {
  if (right) {
    Object.keys(pose).forEach(key => {
      if (key.includes("LeftHand") || key == "mixamorigRightHand") {
        delete pose[key];
      }
    });
  } else {
    Object.keys(pose).forEach(key => {
      if (key.includes("RightHand") || key == "mixamorigLeftHand") {
        delete pose[key];
      }
    });
  }
  sceneManager.selectedManager.character.setPose(pose);
};

export const openSaveScenePopup = () => {
  document.querySelector("#scene-category").value =
    sceneManager.lastSceneCategory || "";
  document.querySelector("#scene-name").value = sceneManager.lastSceneName;
  setIsActive("#save-scene-popup");
};

export const saveNewScene = () => {
  const category = document.querySelector("#scene-category").value;
  const name = document.querySelector("#scene-name").value;
  if (name) {
    saveScene(category || undefined, name);
    setIsInactive("#save-scene-popup");
  }
};

export const saveExistingScene = () => {
  saveScene(sceneManager.lastSceneCategory, sceneManager.lastSceneName);
};

export const saveScene = async (category, name) => {
  sceneManager.lastSceneName = name;
  sceneManager.lastSceneCategory = category;

  const sameNamedScenes = Object.entries(store.state.savedScenes).filter(
    scene => scene[1].category == category && scene[1].name == name
  );

  let sameName = null;
  if (sameNamedScenes.length > 0) {
    const overwrite = confirm(`${t("Overwrite")} ${name}?`);
    if (overwrite) {
      sameName = sameNamedScenes[0][1].id;
    }
  }

  // Upload all props
  for (const prop of sceneManager.sceneProps) {
    if (prop.fileToUpload) {
      await prop.sendToSpaces(prop.fileToUpload)
    }
  }

  api
    .saveScene(
      license.key,
      name,
      JSON.stringify(sceneManager.getSceneState(true)),
      category,
      sameName
    )
    .then(() =>
      showMessage(`${name} ${sameName ? t("updated") : t("saved")}`, 2000, 300)
    );
};

const upload = async (accept, callback) => {
  try {
    const input = document.createElement("input");
    const limit = 10;
    input.type = "file";
    input.accept = accept;
    input.style = "display: none";
    document.body.appendChild(input);

    input.addEventListener(
      "change",
      event => {
        if (event.target.files.length < 1) {
          input.remove();
          return false;
        }
        const fileList = event.target.files;
        if (fileList[0].size > limit * 1000000) {
          showMessage(`${t("File over the")} ${limit}mb ${t("limit")}.`);
          input.remove();
          return false;
        }

        const reader = new FileReader();
        reader.readAsDataURL(fileList[0]);
        reader.onload = (() => {
          input.remove();
          return () => callback(fileList[0]);
        })(fileList[0]);
      },
      false
    );
    input.click();
  } catch (error) {
    console.error(`Error on uploading input: ${error}`);
  }
};

export const exportScene = () => {
  const sceneName = prompt(t("Scene name"));
  if (sceneName) {
    const data =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(sceneManager.getSceneState()));
    const a = document.createElement("a");
    a.href = data;
    a.target = "_self";
    a.download = sceneName + ".jsm";
    a.click();
    a.remove();
  }
};

export const importScene = (file = null) => {
  if (!license.check()) return;

  if (!file) {
    upload(".jsm", file => setScene(file));
  } else {
    setScene(file);
  }
};

const setScene = file => {
  if (confirm(`${t("Overwrite with")} ${file.name}?`)) {
    const reader = new FileReader();
    reader.addEventListener(
      "loadend",
      content => {
        const value = JSON.parse(content.target.result);
        const name = file.name;
        sceneManager.loadScene(name, null, value);
        sceneManager.stateManager.storeSceneState();
      },
      false
    );

    if (file) {
      reader.readAsText(file);
    }
  }
};

export const exportPose = () => {
  const poseName = prompt(t("Pose name"));
  if (poseName) {
    const data =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(
        JSON.stringify(sceneManager.selectedManager.character.getPose())
      );
    const a = document.createElement("a");
    a.href = data;
    a.target = "_self";
    a.download = poseName + ".jsmp";
    a.click();
    a.remove();
  }
};

export const importPose = file => {
  if (!license.check()) return;

  if (!file) {
    upload(".jsmp", file => setPose(file));
  } else {
    setPose(file);
  }
};

const setPose = file => {
  if (
    confirm(
      `Set ${file.name} to ${sceneManager.selectedManager.character.title}?`
    )
  ) {
    const reader = new FileReader();
    reader.addEventListener(
      "loadend",
      content => {
        const value = JSON.parse(content.target.result);
        const name = file.name;
        sceneManager.lastPoseName = name;
        sceneManager.selectedManager.character.setPose(value);
      },
      false
    );

    if (file) {
      reader.readAsText(file);
    }
  }
};
