export const addInstallBanner = (title, logo, delay=2000) => {
    const bannerHTML = createBanner(title, logo)
    document.body.insertAdjacentHTML('afterbegin', bannerHTML);

    const $appBanner = document.querySelector('.app-banner')
    const $instructionsBanner = document.querySelector('.instructions-banner')
    const $getButton = $appBanner.querySelector('.get-button')
    const $appCloseButton = $appBanner.querySelector('.close-button')
    const $instructionsCloseButton = $instructionsBanner.querySelector('.close-button')

    $appCloseButton.addEventListener('click', () => {
        $appBanner.classList.add("hidden")
    })

    $instructionsCloseButton.addEventListener('click', () => {
        $instructionsBanner.classList.add("hidden")
    })

    $getButton.addEventListener('click', () => {
        $appBanner.classList.add("hidden")
        $instructionsBanner.classList.remove("hidden")
    })

    setTimeout(() => {
        $appBanner.classList.remove("hidden")
    }, delay)
}

const createBanner = (title, icon) => {
    return `
      <div class="app-banner hidden">
        <div class="close-button">✕</div>
        <div class="app-icon" style="background-image: url('${icon}')"></div>
        <div class="app-info">
          <div class="app-title">${title}</div>
          <div class="app-subtitle">Download the app</div>
        </div>
        <button class="get-button">GET</button>
      </div>
  
      <div class="instructions-banner hidden">
        <div class="close-button">✕</div>
        <p>
          Install <b>${title}</b> on your device
        </p>
        <p>
          Tap
          <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItc2hhcmUiPjxwYXRoIGQ9Ik00IDEydjhhMiAyIDAgMCAwIDIgMmgxMmEyIDIgMCAwIDAgMi0ydi04Ij48L3BhdGg+PHBvbHlsaW5lIHBvaW50cz0iMTYgNiAxMiAyIDggNiI+PC9wb2x5bGluZT48bGluZSB4MT0iMTIiIHkxPSIyIiB4Mj0iMTIiIHkyPSIxNSI+PC9saW5lPjwvc3ZnPg==" alt="Share Icon" />
          below
          <br>
          <br>
          Select
          <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGNsYXNzPSJmZWF0aGVyIGZlYXRoZXItcGx1cy1zcXVhcmUiPjxyZWN0IHg9IjMiIHk9IjMiIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgcng9IjIiIHJ5PSIyIj48L3JlY3Q+PGxpbmUgeDE9IjEyIiB5MT0iOCIgeDI9IjEyIiB5Mj0iMTYiPjwvbGluZT48bGluZSB4MT0iOCIgeTE9IjEyIiB4Mj0iMTYiIHkyPSIxMiI+PC9saW5lPjwvc3ZnPg==" alt="Plus Square Icon" />
          <b>Add to Home Screen</b>
        </p>
        <p>
          <small>
            No App Store required
            <br>
            <a href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps" target="_blank">
              Learn more
            </a>
          </small>
        </p>
        ↓
      </div>
    `
}
