import { sceneManager } from '../index';
import { scene } from '../SceneManager';

class StateManager {
    constructor() {
        this.scenePrevStore = [];
        this.sceneNextStore = [];
    }

    reset () {
        this.scenePrevStore = [];
        this.sceneNextStore = [];
    }

    getSubjectState(subject) {
        if (sceneManager.loading()) {
            return
        }

        const subjectProperties = {
            info: subject.info,
            id: subject.id,
            position: subject.getPosition(),
            scale: subject.getScale(),
            rotation: subject.getRotation(),
            pose: subject.subjectType == "Character" ? subject.getPose() : null,
            objectInfo: subject.objectInfo,
            imageUrl: subject.imageUrl,
            title: subject.title,
            alive: subject.alive,
            color: subject.color,
        }

        return subjectProperties
    }

    storeSceneState () {
        // Pushing last change into state
        const currentState = this.getSubjectState(sceneManager.selectedManager.subject)

        if (currentState != this.scenePrevStore.lastItem) {
            this.scenePrevStore.push(currentState);
            
            this.scenePrevStore = [...new Set(this.scenePrevStore)];
            this.sceneNextStore = [];
        }
    }

    updateScene (subjectProperties) {
        if (!subjectProperties) {
            return
        }
        
        const subject = sceneManager.sceneCharacters.find(character => character.id === subjectProperties.id)
        || sceneManager.sceneProps.find(prop => prop.id === subjectProperties.id)

        if (subject) {
            if (!subject.alive && subjectProperties.alive) {
                subject.reinstate()
            } else if (subject.alive && !subjectProperties.alive) {
                subject.delete()
            }

            if (subject.subjectType == "Character") {
                subject.setPose(subjectProperties.pose, true, false)
            } else {
                subject.setRotation(subjectProperties.rotation)
            }
            
            subject.setScale(subjectProperties.scale)
            subject.setPosition(subjectProperties.position)
        }
    }

    undo () {
        if (this.scenePrevStore.length > 0) {
            const prevState = this.scenePrevStore.pop();
            const currentState = this.getSubjectState(sceneManager.selectedManager.subject)
            if(prevState != currentState) {
                this.sceneNextStore.push(currentState);
            }
            this.updateScene(prevState);
        }
    }
    
    redo () {
        if (this.sceneNextStore.length > 0) {
            const nextState = this.sceneNextStore.pop();
            
            const currentState = this.getSubjectState(sceneManager.selectedManager.subject)
            if(nextState != currentState) {
                this.scenePrevStore.push(currentState);
            }

            this.updateScene(nextState);
        }
    }
}

export default StateManager;