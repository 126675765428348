import { t } from "./helpers/Translate"
const element = document.querySelector('#alert')
let timeoutId = 0

element.onclick = () => {
    element.classList.remove('visible')
    timeoutId = 0
}

export const showMessage = (message, duration=3000, delay=0) => {
    clearTimeout(timeoutId)
    element.innerHTML = ""

    setTimeout(() => {
        element.innerHTML = t(message)
        element.classList.add('visible')
    
        timeoutId = setTimeout(() => {
            element.classList.remove('visible')
        }, duration)
    }, delay)
}

export const showConfirm = (message, onComplete, onCancel) => {
    clearTimeout(timeoutId)
    element.innerHTML = t(message)
    element.classList.add('visible')
    const okButton = document.createElement("button")
    okButton.innerText = "Yes"
    okButton.classList.add('button')
    okButton.classList.add('small')
    okButton.onclick = () => {
        onComplete()
        element.classList.remove('visible')
    }
    element.append(okButton)

    const cancelButton = document.createElement("button")
    cancelButton.innerText = "No"
    cancelButton.classList.add('button')
    cancelButton.classList.add('small')
    cancelButton.onclick = () => {
        onCancel()
        element.classList.remove('visible')
    }
    element.append(cancelButton)
}