import "../css/index.scss"
import Store from "./Store"
import AdminTools from "./AdminTools"
import SceneManager from "./SceneManager"
import License from "./License"
import Loader from "./Loader"
import API from "./API"
import { openMenu, closeAllMenus, buildMenus } from "./helpers/Menus"
import { translateTextualContent, t } from "./helpers/Translate"
import {
	importPose,
	importScene,
	saveNewPose,
	saveNewScene,
} from "./helpers/SaveManager"
import "./helpers/ShortcutHandler"
import { showMessage } from "./Message"
import { showDiscountPopup } from "./constants/ppp"
import { addInstallBanner } from "./helpers/AppInstallBanner"

const version = "4.3"
document.querySelector('#version-number').innerHTML = version

if ("serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		navigator.serviceWorker
			.register("/service-worker.js")
			.then((registration) => {
				console.log("SW registered: ", registration)
			})
			.catch((registrationError) => {
				console.log("SW registration failed: ", registrationError)
			})
	})
}

const urlParams = new URLSearchParams(window.location.search)
if (urlParams.has("key")) {
	localStorage.setItem("licence_key", urlParams.get("key"))
	window.history.pushState("", "/", "/")
}

if (urlParams.has("lang")) {
	localStorage.setItem("lang", urlParams.get("lang"))
	window.history.pushState("", "/", "/")
}

translateTextualContent()

const canvas = document.getElementById("canvas")

export const store = new Store()
export const admin = new AdminTools()
export const loader = new Loader()
export const api = new API()
export const license = new License()

export const sceneManager = new SceneManager(canvas)

const resizeCanvas = () => {
	canvas.style.width = window.innerWidth
	canvas.style.height = window.innerHeight

	canvas.width = window.innerWidth
	canvas.height = window.innerHeight

	sceneManager.onWindowResize()
}

const bindEventListeners = () => {
	window.onresize = resizeCanvas
	resizeCanvas()
}

const render = () => {
	requestAnimationFrame(render)
	sceneManager.update()

	if (admin.stats) {
		admin.stats.update()
	}
}

bindEventListeners()
render()

export const setIsActive = (selector) => {
	const element =
		typeof selector == "object" ? selector : document.querySelector(selector)
	element.classList.add("is-active")
}

export const setIsInactive = (selector) => {
	const element =
		typeof selector == "object" ? selector : document.querySelector(selector)
	element.classList.remove("is-active")
}

export const toggleIsActive = (selector) => {
	const element =
		typeof selector == "object" ? selector : document.querySelector(selector)
	if (element.classList.contains("is-active")) {
		setIsInactive(selector)
	} else {
		setIsActive(selector)
	}
}

const createToolTip = (button, position) => {
	const tooltip = document.createElement("div")
	tooltip.classList.add("tooltip")
	tooltip.classList.add(position)
	tooltip.innerHTML = t(button.title)
	button.append(tooltip)
}

// Initialize app

if (!license.valid) {
	showDiscountPopup()
}


// showMessage(`
// <b class="hl chilled-pink">Some exciting new updates:</b>
// <br><br>
// <b>Mirror posing mode <svg><use xlink:href="images/feather-sprite.svg#columns" /></svg></b>
// and
// <b>save/restore camera position <svg><use xlink:href="images/feather-sprite.svg#camera" /></svg></b> (top right).
// <br><br>
// <b>Adjust limb length and body scale</b> (experimental and buggy as heck)
// <br>
// <small>This is only available to JustSketchMe Pro subscribers and can be turned on in the settings menu <svg><use xlink:href="images/feather-sprite.svg#settings" /></svg>.</small>
// `, 30000)


setTimeout(() => {
	if (!license.valid) {
		openMenu('#upgrade-button', '#upgrade-menu')
	}
}, 120000)

if (!localStorage.getItem("hideTutorial")) {
	document.querySelector('.tutorial').classList.add('is-active')
}

document.querySelectorAll(`.objects-menu .tool-button`).forEach((button) => {
	createToolTip(button)
})

document.querySelectorAll(`.settings-menu .tool-button`).forEach((button) => {
	createToolTip(button, "right")
})

document.querySelectorAll(`.gizmos .tool-button`).forEach((button) => {
	createToolTip(button, "bottom")
})

document.querySelectorAll(`.scenes-menu .tool-button`).forEach((button) => {
	createToolTip(button, "bottom-right")
})

window.setIsWebView = (value) => {
	window.isWebView = value
	buildMenus()
}
window.openMenu = openMenu
window.closeAllMenus = closeAllMenus
window.setIsActive = setIsActive
window.setIsInactive = setIsInactive
window.toggleIsActive = toggleIsActive
window.toggleTutorial = () => {
	if (localStorage.getItem('hideTutorial')) {
		localStorage.removeItem('hideTutorial')
		setIsActive('.tutorial')
	} else {
		localStorage.setItem('hideTutorial', true)
		setIsInactive('.tutorial')
	}
}
window.saveNewPose = saveNewPose
window.saveNewScene = saveNewScene
window.license = license
window.sceneManager = sceneManager
window.store = store
window.copyToClipboard = (title, text) => {
	navigator.clipboard.writeText(text).then( function () {
		alert(title + ' ' + t('copied to clipboard'));
	}, function () {
		alert('Copy to clipboard failed');
	});
}

let overlayTimeout = {}

window.dragOverHandler = (event) => {
	document.querySelector("#drop-overlay").style.display = "flex"
	clearTimeout(overlayTimeout)
	overlayTimeout = setTimeout(
		() => (document.querySelector("#drop-overlay").style.display = "none"),
		300
	)
	event.preventDefault()
}

window.dropHandler = (event) => {
	event.preventDefault()
	if (event.dataTransfer.items) {
		for (var i = 0; i < event.dataTransfer.items.length; i++) {
			if (event.dataTransfer.items[i].kind === "file") {
				var file = event.dataTransfer.items[i].getAsFile()

				console.log("... file[" + i + "].name = " + file.name)

				if (file.name.endsWith(".jsm")) {
					importScene(file)
				} else if (file.name.endsWith(".jsmp")) {
					if (sceneManager.selectedManager.character) {
						importPose(file)
					} else {
						showMessage("No character to apply pose to")
					}
				}

				if (
					file.name.endsWith(".jpg") ||
					file.name.endsWith(".jpeg") ||
					file.name.endsWith(".png")
				) {
					if (!license.check()) return
					const reader = new FileReader()
					reader.addEventListener(
						"loadend",
						(content) => {
							sceneManager.addPropToScene({
								type: "image",
								objectInfo: content.target.result,
								title: file.name,
							})
						},
						false
					)

					if (file) {
						reader.readAsDataURL(file)
					}
				}

				if (file.name.endsWith(".obj")) {
					if (!license.check()) return
					const reader = new FileReader()
					reader.addEventListener(
						"loadend",
						(content) => {
							sceneManager.addPropToScene({
								type: "model",
								objectInfo: content.target.result,
								title: file.name
							})
						},
						false
					)

					if (file) {
						reader.readAsDataURL(file)
					}
				}
			}
		}
	} else {
		// Use DataTransfer interface to access the file(s)
		for (var i = 0; i < event.dataTransfer.files.length; i++) {
			console.log(
				"... file[" + i + "].name = " + event.dataTransfer.files[i].name
			)
			console.log("Not catering for this yet")
		}
	}
}

let tapTimer = 0

const secondaryTap = event => {
	sceneManager.selectedManager.tapHandler(event, "secondary")
}

const tapStart = event => {
	closeAllMenus()
	sceneManager.selectedManager.tapHandler(event)

	// Long touch
	tapTimer = setTimeout(() => {
		secondaryTap(event)
	}, 1000)
}

const tapEnd = event => {
	clearTimeout(tapTimer)
}

document.querySelector("canvas").addEventListener(
	"mousedown",
	event => tapStart(event),
	false
)

document.querySelector("canvas").addEventListener(
	"touchstart",
	event => tapStart(event.touches[0]),
	false
)

document.querySelector("canvas").addEventListener(
	"dblclick",
	event => console.log("Double clicked"),
	false
)

document.querySelector("canvas").addEventListener(
	"mouseup",
	event => tapEnd(event),
	false
)

document.querySelector("canvas").addEventListener(
	"touchend",
	event => tapEnd(event),
	false
)

// Save and set camera position

const cameraPositionButton = document.getElementById('camera-position-button');
let timer;

function startTimer() {
  event.preventDefault();
  timer = setTimeout(sceneManager.saveCameraPosition, 1000);
}

function clearTimer() {
  clearTimeout(timer);
}

function handleClick(e) {
  if (e.detail === 1 || e.type === 'touchend') {
    sceneManager.setCameraPosition();
  }
}

cameraPositionButton.addEventListener('mousedown', startTimer);
['mouseup', 'mouseleave'].forEach(type => cameraPositionButton.addEventListener(type, clearTimer));
cameraPositionButton.addEventListener('click', handleClick);

cameraPositionButton.addEventListener('touchstart', startTimer);
['touchend', 'touchcancel'].forEach(type => cameraPositionButton.addEventListener(type, clearTimer));

cameraPositionButton.addEventListener('touchend', handleClick);

// Show install PWA prompt for iOS
const isIOS = () => {
	const userAgent = window.navigator.userAgent.toLowerCase()
	return /iphone|ipad|ipod/.test(userAgent)
}
  
const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone)

setTimeout(() => {
	if (isIOS() && !isInStandaloneMode() && !window.isWebView) {
		addInstallBanner('JustSketchMe', 'images/favicons/icon-144x144.png')
	}
}, 1900)


// Makes sure everything is kinda working or error message
document.querySelector(".js-error").style.display = "none"
