import * as THREE from "three"
import * as TWEEN from "../OverwriteThreeJS/tween"

export const buildRenderer = (width, height) => {
	const renderer = new THREE.WebGLRenderer({
		canvas: canvas,
		antialias: true,
		alpha: true,
		preserveDrawingBuffer: true,
	})
	const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1
	renderer.setPixelRatio(DPR)
	renderer.setSize(width, height)
	renderer.shadowMap.enabled = true
	renderer.gammaInput = true
	renderer.gammaOutput = true
	renderer.physicallyCorrectLights = true

	return renderer
}

export const buildCamera = ({ width, height }) => {
	const aspectRatio = width / height
	const fieldOfView = 45
	const nearPlane = 1
	const farPlane = 3000
	const cam = new THREE.PerspectiveCamera(
		fieldOfView,
		aspectRatio,
		nearPlane,
		farPlane
	)

	cam.position.set(100, 200, 300)

	return cam
}

const asyncForEach = async (array, callback) => {
	for (let index = 0; index < array.length; index++) {
		await callback(array[index], index, array)
	}
}

export const animateTransition = (from, to, onUpdate) => {
	const animate = (time) => {
		requestAnimationFrame(animate)
		TWEEN.update(time)
	}

	const tween = new TWEEN.Tween(from)
		.to(to, 300)
		.onUpdate(function () {
			onUpdate(this)
		})
		.easing(TWEEN.Easing.Quadratic.Out)
		.start()

	requestAnimationFrame(animate)
}

export let connected = true

// const checkConnection = async () => {
// 	const fetchTest = async () => {
        // try {
		// 	const online = await fetch("https://justsketch.me/sitemap")
        //     const status = online.status >= 200 && online.status < 300 // either true or false
        //     if (connected != status) {
        //         connected = status
        //         console.log("Internet connection: ", status)
        //     }
			
		// } catch (err) {
		// 	const status = false // definitely offline
        //     if (connected != status) {
        //         connected = status
        //         console.log("Internet connection: ", status)
        //     }
		// }
//     }
//     fetchTest()
// 	setInterval(async () => {
//         fetchTest()
// 	}, 10000)
// }

// checkConnection()
