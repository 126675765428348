import { connected } from "./helpers/Helpers";
import { loader, store } from "./index";
import { showMessage } from "./Message";

class API {
  constructor() {
    // this.apiUrl = "http://lh.co";
    this.apiUrl = "https://larry.justsketch.me";
  }

  menuLoader(query, message) {
    document.querySelector(
      query
    ).innerHTML = `<p class='loader'><svg><use xlink:href='images/feather-sprite.svg#activity'/></svg><br>${message}</p>`;
  }

  buttonLoader(query) {
    document.querySelector(query).innerHTML =
      '<span class="button-loader"><svg><use xlink:href="images/feather-sprite.svg#activity"/></svg></span>';
  }

  async validateLicenseKey(key) {
    if (!connected) {
      showMessage("Internet connection required to validate key", 4000, 300);
      return false;
    }
    showMessage("Checking license key...", 4000, 300);

    const gumroadResult1 = await fetch(
      "https://api.gumroad.com/v2/licenses/verify",
      {
        body: `product_permalink=MjNqbv&license_key=${key}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
      }
    ).then(response => response.json());

    const gumroadResult2 = await fetch(
      "https://api.gumroad.com/v2/licenses/verify",
      {
        body: `product_permalink=UFFST&license_key=${key}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
      }
    ).then(response => response.json());

    const lemonSqueezyResult = await fetch(
      "https://api.lemonsqueezy.com/v1/licenses/validate",
      {
        body: `license_key=${key}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
      }
    ).then(response => response.json());

    const larryResult = await fetch(
      `${this.apiUrl}/validate/${key.trim()}/`
    ).then(response => response.json());

    if (
      larryResult["result"] == "valid" ||
      larryResult["result"] == "expired"
    ) {
      if (larryResult["result"] == "valid") {
        return larryResult;
      } else if (larryResult["result"] == "expired") {
        showMessage("This license key has expired");
      }
    } else if (gumroadResult1["success"]) {
      if (
        this.checkDateValid(gumroadResult1.purchase.subscription_cancelled_at)
      ) {
        return gumroadResult1;
      } else {
        showMessage("This license key has expired");
      }
    } else if (gumroadResult2["success"]) {
      return gumroadResult2;
    } else if (lemonSqueezyResult["valid"]) {
      return lemonSqueezyResult;
    } else {
      showMessage("This license key does not exist or has expired");
    }
    return false;
  }

  checkDateValid(date) {
    if (date === null) {
      return true;
    }
    return new Date(date) > new Date();
  }

  async getLibrary(key) {
    if (!store.state.poseLibrary) {
      this.menuLoader("#pose-menu", "Loading poses...");
      this.menuLoader("#hand-pose-menu", "Loading hand poses...");
    }

    return await fetch(`${this.apiUrl}/libraries/poses/`).then(response =>
      response.json()
    );
  }

  async getPropPacks(key) {
    return await fetch(`${this.apiUrl}/media/props/`).then(response =>
      response.json()
    );
  }

  async getPoses(key, silent = false) {
    if (!silent && !store.state.savedPoses) {
      this.menuLoader("#pose-menu", "Loading poses...");
    }
    return await fetch(
      `${this.apiUrl}/userstore/poses/get/?key=${key.trim()}`
    ).then(result => result.json());
  }

  async getPose(key, id) {
    loader.startLoading(id, "Fetching your pose...");
    return await fetch(
      `${this.apiUrl}/userstore/poses/get/${id}/?key=${key.trim()}`
    ).then(result => {
      loader.stopLoading(id);
      return result.json();
    });
  }

  async savePose(key, name, values, category, id = null) {
    this.buttonLoader("#pose-menu button");

    return await fetch(
      `${this.apiUrl}/userstore/poses/save/?key=${key.trim()}`,
      {
        method: "POST",
        body: JSON.stringify({
          name,
          values,
          id,
          category
        })
      }
    ).then(data => {
      this.getPoses(key, true).then(data => {
        store.setState({ savedPoses: data });
      });
    });
  }

  async deletePose(key, id) {
    store.setState({
      savedPoses: store.state.savedPoses.filter(pose => pose.id != id)
    });
    return await fetch(
      `${this.apiUrl}/userstore/poses/delete/${id}/?key=${key.trim()}`
    ).then(result => result.json());
  }

  async getScenes(key, silent = false) {
    if (!silent && !store.state.savedScenes) {
      this.menuLoader("#scene-menu", "Loading scenes...");
    }
    return await fetch(
      `${this.apiUrl}/userstore/scenes/get/?key=${key.trim()}`
    ).then(result => result.json());
  }

  async getScene(key, id) {
    loader.startLoading(id, "Fetching your scene...");
    return await fetch(
      `${this.apiUrl}/userstore/scenes/get/${id}/?key=${key.trim()}`
    ).then(result => {
      loader.stopLoading(id);
      return result.json();
    });
  }

  async saveScene(key, name, values, category, id = null) {
    this.buttonLoader("#scene-menu button");
    console.log(values);
    return await fetch(
      `${this.apiUrl}/userstore/scenes/save/?key=${key.trim()}`,
      {
        method: "POST",
        body: JSON.stringify({
          name,
          values,
          id,
          category
        })
      }
    ).then(data => {
      this.getScenes(key, true).then(data => {
        store.setState({ savedScenes: data });
      });
    });
  }

  async deleteSavedScene(key, id) {
    store.setState({
      savedScenes: store.state.savedScenes.filter(scene => scene.id != id)
    });
    return await fetch(
      `${this.apiUrl}/userstore/scenes/delete/${id}/?key=${key.trim()}`
    ).then(result => result.json());
  }
}

export default API;
